import React, { useEffect } from "react";
import {
  Tab,
  Tabs,
} from "@mui/material";
import {  Colors,  } from "@monorepo/shared";
import { useAppContext } from "contexts/AppContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";



export const Users = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {setTabs} = useAppContext();

  useEffect(() => {
    const pathOptions = ["/home/users"]
    const currentTabValue =
      pathOptions.includes(location.pathname) ? "/home/users/referees" : location.pathname;

    setTabs(      
      <Tabs
        orientation="vertical"
        value={currentTabValue}
        onChange={(e, value) => (navigate(value))}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Domare" value={"/home/users/referees"} sx={{ color: Colors.darkBlue }} />
        <Tab label="Tränare" value={"/home/users/trainers"} sx={{ color: Colors.darkBlue }} />
        <Tab sx={{ display: 'none', color: Colors.darkBlue }} value={"/home/users/blocked"} label="Blockerade" />
      </Tabs>
    ); 
  }, [location.pathname, navigate, setTabs]);

  return (
    <Outlet />
  );
};
