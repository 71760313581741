import React, { useEffect,  } from 'react';
import {  List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {  Colors, emptyMatch, Match,  } from '@monorepo/shared';
import { useMatchData } from 'features/matches/hooks/useMatchData';
import { useAppContext } from 'contexts/AppContext';
import { ScheduleTable } from 'features/matches/components/ScheduleTable';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useMatches } from 'contexts/MatchesContext';

export const Coming = () => {
  const {coming} = useMatchData();
  const {matchInvitations} = useMatches();
  const {setTitle, setTabSettings} = useAppContext();
  const {setSelectedMatch} = useOutletContext<{setSelectedMatch: React.Dispatch<React.SetStateAction<Match>>}>();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Kommande matcher");
    setTabSettings(
      <List>
        <ListItem>
          <ListItemButton component="button" onClick={() => setSelectedMatch(emptyMatch())}>
            <ListItemIcon>
              <AddRoundedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Ny Match" sx={{ textAlign: 'center', color: Colors.white }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component="button" onClick={() => navigate("/home/schedule/examples")}>
            <ListItemIcon>
              <AutorenewIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Generera exempel" sx={{ textAlign: "center", color: Colors.white }} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }, [navigate, setSelectedMatch, setTabSettings, setTitle])

  return (
    <ScheduleTable matches={coming} matchInvitations={matchInvitations} onSelectMatch={(match) => setSelectedMatch(match)}/>
  );
};