import { Stack, Switch, Typography } from "@mui/material"



type SwitchSettingProps = {
  title: string,
  description?: string, 
  value: boolean,
  onChange: (val: boolean) => void
}

export const SwitchSetting = ({title, description, value, onChange}: SwitchSettingProps) => {



  return (
    <Stack justifyContent={"space-between"} direction={"row"} padding={2} alignItems={"center"}>
      <Stack>
        <Typography>
          {title}
        </Typography>
        <Typography color="textSecondary">
          {description}
        </Typography>
      </Stack>
      <Switch checked={value} onChange={(e) => onChange(!value)} color="primary" sx={{marginRight: 2}} />
    </Stack>
  )
}