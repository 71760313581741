import { useState, useEffect, useCallback } from 'react';
import { Match, filterComing, filterPlayed,  sortByDate } from '@monorepo/shared';
import { useMatches } from 'contexts/MatchesContext';



export const useMatchData = () => {
  const { matches } = useMatches();

  const [coming, setComing] = useState<Match[]>([]);
  const [played, setPlayed] = useState<Match[]>([]);

  const formatMatches = useCallback(() => {
    setComing(matches.filter(filterComing).sort(sortByDate));
    setPlayed(matches.filter(filterPlayed).sort((a, b) => 0 - sortByDate(a, b)));
  }, [matches]);

  useEffect(() => {
    formatMatches();
  }, [matches, formatMatches]);

  return { coming, played };
};