  import { Box, Button, Checkbox, Collapse, Divider, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material"
  import { useCallback, useEffect, useState } from "react";
  import ExpandLess from '@mui/icons-material/ExpandLess';
  import ExpandMore from '@mui/icons-material/ExpandMore';
  import { Club, paths } from "@monorepo/shared";
  import InfoIcon from '@mui/icons-material/Info';
  import { getCompensation, getDisplayValue, matchTypes } from "shared/utils/defaultMatchTypes";
  import { doc, updateDoc } from "firebase/firestore";
  import { db } from "config/firebase/firebase";
  import { useClub } from "contexts/ClubContext";
  import { SwitchSetting } from "./SettingsComponents";

  interface GeneralSettingsProps {
   
  }

  export const GeneralSettings = ({}: GeneralSettingsProps) => {    
    const {club} = useClub()
    const [matchTypeSettings, setMatchTypeSettings] = useState(club?.settings.matchTypes || {});
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [resend, setResend] = useState(false);
    const [sendCloseTo, setSendCloseTo] = useState(false);
    const [generateAutomatically, setGenerateAutomatically] = useState(false);


    const reset = useCallback(() => {
      setMatchTypeSettings(club?.settings.matchTypes || {})
      setEmail(club?.settings.email || "");
      setPhone(club?.settings.phone || "");
      setResend(club?.settings.resendOnDecline || false);
      setSendCloseTo(club?.settings.sendCloseToMatch || false);
      setGenerateAutomatically(club?.settings.generateExamplesAutomatically || false);
    }, [club])

    useEffect(() => {
      reset()
    }, [reset])

    
    const saveSettings = async () => {
      const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
      if (!club || !clubRef) return;

      await updateDoc(clubRef, {
        "settings.matchTypes": matchTypeSettings,
        "settings.email": email,
        "settings.phone": phone,
        "settings.resendOnDecline": resend,
        "settings.sendCloseToMatch": sendCloseTo,
      });
    };

    const saveToggleSetting = async (val: boolean, field: string) => {
      const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
      if (!club || !clubRef) return;

      await updateDoc(clubRef, {
        [field]: val,
      });
    }

    const matchTypeActivated = (id: string) => {
      return matchTypeSettings[id] !== undefined
    }

    const matchTypeCompensation = (id: string) => {
      return matchTypeActivated(id) ? matchTypeSettings[id].compensation : getCompensation(id, club?.clubType);
    }

    const handleCompensationChange = (id: string, value: number) => {
      const updatedSettings = {...matchTypeSettings}

      updatedSettings[id].compensation = value;
      setMatchTypeSettings(updatedSettings);
    }

    const handleMatchClick = (id: string) => {
      const updatedSettings = {...matchTypeSettings}
      if (matchTypeSettings[id] !== undefined) {
        delete updatedSettings[id];
      } else {
        updatedSettings[id] = {compensation: getCompensation(id, club?.clubType), displayValue: getDisplayValue(id, club?.clubType)};
      }
      setMatchTypeSettings(updatedSettings);
    }

    return (
      <Box  height={"100%"} overflow={"scroll"} paddingRight={{xs: 0, md: 10}} paddingLeft={{xs: 0, md: 10}}>
        <List component="div" disablePadding onBlur={() => saveSettings()}>
          <ListItem >
            <ListItemText primary= "Kontaktuppgifter" />
            <Tooltip title="Detta är domarens kontaktperson">
              <InfoIcon color="primary"></InfoIcon>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
            <TextField 
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant="standard"
              label="Mail"
              fullWidth        
            />
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
            <TextField 
              value={phone}
              onChange={e => setPhone(e.target.value)}
              variant="standard"
              label="Telefon"
              fullWidth        
            />
          </ListItem>
        </List>
        <Paper elevation={3} sx={{margin:2}} >
          <SwitchSetting value={resend} onChange={(val) => saveToggleSetting(val, "settings.resendOnDecline") } 
            title={"Skicka ut ny inbjudan vid nekande"} 
            description="Skicka automatiskt ut en ny förfrågan då en domare nekar"
          />
          <Divider />
          <SwitchSetting value={sendCloseTo} onChange={(val) => saveToggleSetting(val, "settings.sendCloseToMatch")} 
            title={"Matchförfrågan skickas ut automatiskt då domare ej tillsats"} 
            description="Tre dagar innan match skickas en förfrågan ut till alla behöriga domare om ingen har tagit matchen"
            />
          <Divider />
          <SwitchSetting value={generateAutomatically} onChange={(val) => saveToggleSetting(val, "settings.generateExamplesAutomatically")} 
            title={"Generera exempel automatiskt"} 
            description="Tillsätter domare och ersättning automatiskt för matcher med de matchtyper klubben valt"
            />
        </Paper>
        <List component="div" disablePadding onBlur={() => saveSettings()}>
          <ListItem sx={{ pl: 0 }}>
            <ListItemText primary= "Standard match typer" />
            <Tooltip title="Matcher som läggs till automatiskt">
              <InfoIcon color="primary"></InfoIcon>
            </Tooltip>
          </ListItem>
          {matchTypes(club?.clubType).map(matchType => (
            <ListItem key={matchType.uniqueId} sx={{ pl: 4 }}>
              <Checkbox checked={matchTypeActivated(matchType.uniqueId)} onChange={() => handleMatchClick(matchType.uniqueId)} color="primary" sx={{marginRight: 2}} />
              <Typography width={300} paddingRight={5}>
                {matchType.displayValue}: 
              </Typography>
              <TextField 
                variant="standard"
                disabled={!matchTypeActivated(matchType.uniqueId)} 
                onChange={e => handleCompensationChange(matchType.uniqueId, parseInt(e.target.value) || 0)}
                value={matchTypeCompensation(matchType.uniqueId)}   
                fullWidth        
                slotProps={{
                    input: {
                    endAdornment: <InputAdornment position="start">{"kr"}</InputAdornment>,
                    },
                }}
            >
            </TextField>  
          </ListItem>
          ))}
        </List>
      </Box>
    )
  }