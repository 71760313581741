import * as React from 'react';
import Box from '@mui/material/Box';
import { Colors } from "@monorepo/shared";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TopBar } from 'features/landingpage/components/TopBar';
import { Slogan } from 'features/landingpage/components/Slogan';
import { ForClubs, Prices } from 'features/landingpage/components/Sections';
import { AboutUs } from 'features/landingpage/components/AboutUs';
import { Socials } from 'features/landingpage/components/Socials';
import { ContactUs } from 'features/landingpage/components/Contact';
import { Drawer } from '@mui/material';
import { Questions } from 'features/landingpage/components/Questions';




export const LandingPage = () =>  {
  const navigate = useNavigate();
  const location = useLocation();

  const topRef = React.useRef<HTMLElement>(null);
  const clubsRef = React.useRef<HTMLElement>(null);
  const questionsRef = React.useRef<HTMLElement>(null);
  const pricesRef = React.useRef<HTMLElement>(null);
  const aboutRef = React.useRef<HTMLElement>(null);
  const contactRef = React.useRef<HTMLElement>(null);

  return (
    <Box sx={{bgcolor: Colors.lightGrey}} ref={topRef}>
      <Drawer anchor="right" open={location.pathname.includes("login") || location.pathname.includes("signup")} >
        <Outlet />
      </Drawer>
      <TopBar topRef={topRef} clubsRef={clubsRef} pricesref={pricesRef} aboutRef={aboutRef} questionsRef={questionsRef} contactRef={contactRef}/>
      <Box sx={{bgcolor: Colors.lightGrey}}>
        <Slogan />
        <Box ref={clubsRef}>
          <ForClubs />
        </Box>
        <Box ref={aboutRef}>
          <AboutUs />
        </Box>
        <Box ref={pricesRef}>
          <Prices />
        </Box>
        <Box ref={questionsRef} padding={{xs: 2, md: 20}} sx={{backgroundColor: Colors.primary}}>
          <Questions />
        </Box>
        <Socials />
        <Box ref={contactRef} >
          <ContactUs/>
        </Box>
      </Box>
    </Box>
  );
}
