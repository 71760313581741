import React, { useState, useEffect, useCallback } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import {  Colors, emptyMatch, Match, MatchInvite, } from '@monorepo/shared';
import { useMatchData } from 'features/matches/hooks/useMatchData';
import { useAppContext } from 'contexts/AppContext';
import { MatchEditor } from 'features/matches/components/MatchEditor';
import { ScheduleTable } from 'features/matches/components/ScheduleTable';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { generateExampleMatches } from '../utils/generateExamples';
import { saveExamples } from '../utils/saveExamples';
import { useClub } from 'contexts/ClubContext';
import { useReferees } from 'contexts/RefereesContext';
import { useMatches } from 'contexts/MatchesContext';

export const Examples = () => {
  const {club} = useClub();
  const {referees} = useReferees();
  const {matchInvitations} = useMatches();

  const {coming} = useMatchData();
  const {setTitle, setTabSettings} = useAppContext();
  const {setSelectedMatch} = useOutletContext<{setSelectedMatch: (match: Match) => void}>();
  const navigate = useNavigate();

  const {setStatusMessage, setOpenStatusMessage, setStatusMessageType} = useAppContext();

  const [exampleMatches, setExampleMatches] = useState<{[matchId: string]: Match}>({});
  const [exampleInvites, setExampleInvites] = useState<{[matchId: string]: MatchInvite[]}>({});
  const [displayMatches, setDisplayMatches] = useState<Match[]>([]);



  useEffect(() => {
    if (!club) {
      return;
    }
    const {newExampleMatches, newInviteExamples} = generateExampleMatches(coming, referees, matchInvitations, club?.settings!);
    console.log("newExampleMatches")
    console.log(newExampleMatches)
    console.log("newInviteExamples")
    console.log(newInviteExamples)
    setExampleInvites(newInviteExamples);
    setExampleMatches(newExampleMatches);

    const newDisplayInvites = {...matchInvitations};
    const newDisplayMatches = coming.map(match => {
      if (!match.id) {
        return match
      }
      const newInvites = newInviteExamples[match.id];
      if (newInvites && newInvites.length > 0) {
        newDisplayInvites[match.id] = newInvites;
      }
      return newExampleMatches[match.id] || match;
    })
    setDisplayMatches(newDisplayMatches);
  }, [club, coming, referees, matchInvitations]);

  const acceptExamples = useCallback(async () => {
    const examples = Object.values(exampleMatches);
    if (!club || examples.length <= 0) {
      return;
    }
    try {
      await saveExamples(club.id!, examples, exampleInvites);
      setStatusMessage("Exempel sparade!");
      navigate("/home/schedule/coming");
    } catch (error) {
      setStatusMessage("Misslyckades med att spara exempel");
      setStatusMessageType("error");
      console.error("Error saving matches:", error);
    } finally {
      setOpenStatusMessage(true);
    }    
  }, [club, exampleInvites, exampleMatches, navigate, setOpenStatusMessage, setStatusMessage, setStatusMessageType]);


  useEffect(() => {
    setTitle("Exempel matcher");
    setTabSettings(
      <List>
        <ListItem>
          <ListItemButton component="button" onClick={() => setSelectedMatch(emptyMatch())}>
            <ListItemIcon>
              <AddRoundedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Ny Match" sx={{ textAlign: 'center', color: Colors.white }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton component="button" onClick={() => navigate("/home/schedule/coming")}>
              <ListItemText primary="Avbryt" sx={{ textAlign: "center", color: Colors.white }} />
            </ListItemButton>
            <ListItemButton component="button" onClick={acceptExamples}>
              <ListItemText primary="Spara" sx={{ textAlign: "center", color: Colors.white }} />
            </ListItemButton>
          </ListItem>
      </List>
    );
  }, [acceptExamples, navigate, setSelectedMatch, setTabSettings, setTitle])

  return (
    <ScheduleTable matches={displayMatches} matchInvitations={exampleInvites} onSelectMatch={(match) => setSelectedMatch(match)}/>
  );
};