import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, Typography, Tooltip } from '@mui/material';
import { Colors, filterComing, formatTime, Match, MatchInvite} from '@monorepo/shared';
import { displayInvites } from './DisplayReferee';
import InfoIcon from '@mui/icons-material/Info';

interface ScheduleTableProps {
  matches: Match[],
  matchInvitations: {[matchId: string]: MatchInvite[]},
  onSelectMatch: (match: Match) => void;
}

export const ScheduleTable: React.FC<ScheduleTableProps> = ({ matches, matchInvitations, onSelectMatch }: ScheduleTableProps) => {
  return (
    <TableContainer    
      sx={{
        flexGrow: 1,
        overflowY: "auto", 
      }} component={Paper}
    >
      <Table stickyHeader >
        <TableHead sx={{width: "100%"}}>
          <TableRow>
            {["", "Tid", "Plats", "Lag", "Division", "Domare"].map(title => (
              <TableCell key={title} sx={{ whiteSpace: 'nowrap', bgcolor: Colors.lightGrey}}><span style={{fontWeight: "bolder"}}>{title}</span></TableCell>
            ))}
            <TableCell sx={{ whiteSpace: 'nowrap', bgcolor: Colors.lightGrey}} align="right"><span style={{fontWeight: "bolder"}}>Ersättning (per domare)</span></TableCell>
          </TableRow>
        </TableHead>  
        <TableBody >
          {(matches).map((match, i) => (
            <TableRow  key={i} hover onClick={() => { onSelectMatch(match) }} sx={{ cursor: 'pointer' }}>
              <TableCell sx={{ whiteSpace: 'nowrap',}}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography>
                    {i}
                  </Typography>
                  {match.statusMessages && match.statusMessages.length >= 1 && 
                  <Tooltip title={match.statusMessages.map(msg => <Typography fontSize={"small"}>{msg}</Typography>)}>
                    <InfoIcon  fontSize='small' sx={{ marginLeft: 2, color: Colors.primary }}/>
                  </Tooltip>} 
                </Stack>
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatTime(match.time)}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.place}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                <Stack direction="column">
                  <span>{match.home}</span>
                  <span>{match.away}</span>
                </Stack>
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.division}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInvites(filterComing(match), matchInvitations[match.id!])}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                {match.compensation ? match.compensation + "  kr" : ""}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}