import * as React from "react";
import { Stack, Typography, Link, TextField, Button, Box, Autocomplete, Select, MenuItem, FormControl, InputLabel, CircularProgress, LinearProgress, FormGroup, FormControlLabel, Checkbox, Modal, emphasize, IconButton, Alert } from "@mui/material";
import { Colors, Invitation, paths, User as ClubUser, Club } from "@monorepo/shared";
import { getDistricts, getClubs  } from "@monorepo/shared";
import {createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, User} from "firebase/auth"
import { auth, db } from "../../../config/firebase/firebase";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { emptyUser, isweakPassword, signUp, userFilledOut } from "../utils/user";
import { AuthDrawer } from "./Drawer";


export const SignUp = () => {

  const [user, setUser] = React.useState<ClubUser>(emptyUser());
  const [password, setPassword] = React.useState("");
  const [repeatPassword, setRepeatPassword] = React.useState("");
  
  const [generalPolicy, setGeneralPolicy] = React.useState(false);
  const [integrity, setIntegrity] = React.useState(false);

  const [errorText, setErrorText] = React.useState("");

  const [policy, setPolicy] = React.useState("");

  const navigate = useNavigate();
  const handleClick = async () => {
    if (await signUp(user, password, setErrorText)) {
      navigate("/welcome/");
    }
  }

  return (
    <AuthDrawer>
      <Stack role="presentation">
        <TextField  id="mail" label="Mail" variant="standard" value={user.email} onChange={e => setUser({...user, email: e.target.value})} />
        <Stack direction={"row"} spacing={3}>
          <TextField id="name" label="Namn" variant="standard" value={user.name} onChange={e => setUser({...user, name: e.target.value})}/>
          <TextField  id="phone" label="Telefon" variant="standard" onChange={e => setUser({...user, phoneNumber: e.target.value})}/>
        </Stack>
        {<Stack direction={"row"} spacing={3}>
          <TextField fullWidth type='password' error={password !== "" && isweakPassword(password) !== ""} helperText={isweakPassword(password)} value={password} id="password" label="Lösenord" variant="standard" onChange={e => setPassword(e.target.value)}/>
          <TextField error={password !== repeatPassword} value={repeatPassword} helperText={password !== repeatPassword && "lösenord matchar inte"} fullWidth type='password' id="passwordRepeat" label="Repetera Lösenord" variant="standard" onChange={e => setRepeatPassword(e.target.value)}/>
        </Stack>}
          <FormGroup>
            <FormControlLabel 
              control={<Checkbox />} 
              onChange={(e, val) => setGeneralPolicy(val)}
              label={
                <Typography noWrap>
                  Jag godkänner de&nbsp;
                  <Link onClick={(e) => {
                      e.preventDefault(); // Prevent checkbox toggle
                      window.location.href = "/Användarvillkor_Föreningar.html"; 
                    }}
                    >
                      almänna användarvillkoren
                  </Link>
                </Typography>
              }
            />
            <FormControlLabel 
              control={<Checkbox />} 
              onChange={(e, val) => setIntegrity(val)}
              label={
                <Typography noWrap>
                  Jag godkänner&nbsp;
                  <Link onClick={(e) => {
                      e.preventDefault(); // Prevent checkbox toggle
                      window.location.href = "/Integritetspolicy_för_Reffi.html"; 
                    }}
                  > 
                    integritetspolicyn
                  </Link>
                </Typography>
              }
            />
        </FormGroup> 
        {errorText !== "" && 
          <Alert severity="error" >
            {errorText}
          </Alert>
        }
        <Button disabled={!userFilledOut(user, integrity, generalPolicy) || password !== repeatPassword} onClick={handleClick} variant="contained">{"Skapa konto"}</Button>
      </Stack>
    </AuthDrawer>
  )
}