import React, { useState } from "react";
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar, 
  Avatar, 
  Box, 
  Button, 
  IconButton, 
  Menu, 
  MenuItem, 
  Paper, 
  Stack, 
  Toolbar, 
  Typography, 
  useScrollTrigger 
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { ContactUs } from 'features/landingpage/components/Contact';
import { useScreenSize } from "features/landingpage/hooks/screenSize";
import { Colors } from "@monorepo/shared";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const tabs = [
  {label: "Kontakt", value: "contact"},
  {label: "FAQ", value: "faq"},
  //{label: "Videor", value: "videos"},
  {label: "Guider", value: "guides"},
]

const questions = [
  {
    question: "Behöver jag använda generera exempel?",
    awnser: `Nej, men det kan hjälpa dig att spara massvis med tid! Generera exempel är en funktion för att planera hela kommande säsongen.
    Den tittar på vilka domare som är lämpliga för varje match och väljer den domare med minst antal dömda och kommande matcher.`,
  }
]

export const Help = () => {
  const { smallScreen } = useScreenSize();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("contact"); 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    console.log(currentTab)
    switch (currentTab) {
      case "contact":
        return <ContactUs />;
      case "guides":
        return (
          <Box sx={{width: "100%", height: "100%"}}>
            <img
              src={require("../../assets/images/Reffi för Föreningar (Fotboll).pdf")}
              alt=""
              style={{ }}
            />
          </Box>
        )
      case "faq":
        return (
          <Box padding={10} height={"100%"}>
            <Typography color={Colors.primary} variant="h4" padding={3}>
              Frågor
            </Typography>
            <Box maxWidth={"100%"}>
              {questions.map(({awnser, question}, index) => {
                return (
                  <Accordion key={index} sx={{margin: 3}}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="awnser"
                      id="question"
                    >
                      <Typography component="span">{question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {awnser}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* App Bar */}
      <AppBar 
        position="sticky" 
        elevation={useScrollTrigger({ disableHysteresis: true, threshold: 100 }) ? 4 : 0} 
        color="primary"
      >
        <Toolbar>
          <Button onClick={() => navigate(-1)}>
            <Avatar 
              sx={{ width: 48, height: 48 }} 
              src={require("../../assets/images/icon.png")}
            />
            <Typography 
              color="white" 
              variant="h4" 
              fontStyle="italic" 
              component="div"
            >
              REFFI
            </Typography>
          </Button>
          {!smallScreen ? (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ paddingLeft: 2, display: "flex", gap: 2 }}>
                {tabs.map(tab => (
                  <Button key={tab.value} variant="contained" color="inherit" onClick={() => setCurrentTab(tab.value)}>
                    {tab.label}
                  </Button>
                ))}
              </Box>
            </Box>
          ) : (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {tabs.map(tab => (
                  <MenuItem key={tab.value} onClick={() => { setCurrentTab(tab.value); handleClose(); }}>
                    {tab.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Stack
        sx={{ 
          backgroundColor: Colors.white,
          flex: 1, 
          display: "flex", 
          flexDirection: "column", 
        }}
      >
        {renderContent()}
      </Stack>
    </Box>
  );
};
