import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import { User, Invitation, paths } from '@monorepo/shared';
import { getDocument, getDocuments } from 'utils/getDocs';
import { addSubscription } from "./ManageSubscriptions";

interface UserState {
  user: User | undefined;
  currentInvitation: (User & Invitation) | undefined;
  userInvitations: Invitation[];
  loadingUser: boolean;
  loadingCurrenInvitation: boolean;

}

const UserContext = createContext<UserState | undefined>(undefined);

export const UserProvider: React.FC<{ userId: string; children: React.ReactNode }> = ({ userId, children }) => {
  const [user, setUser] = useState<User | undefined>();
  const [currentInvitation, setCurrentInvitation] = useState<(User & Invitation) | undefined>();
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingCurrenInvitation, setLoadingCurrenInvitation] = useState(true);

  const [userInvitations, setUserInvitations] = useState<Invitation[]>([])

  useEffect(() => {
    if (!userId) return;
    
    setLoadingUser(true);
    const userRef = doc(db, paths.clubUsers, userId);

    const unsubscribeUser = onSnapshot(userRef, async () => {
      const userDoc = await getDocument(userRef) as User;
      setUser({ ...userDoc});
      setLoadingUser(false);

    });

    addSubscription(unsubscribeUser);

    return () => {
      unsubscribeUser();
    };
  }, [userId]);

  useEffect(() => {
    if (!userId || !user) return;
    
    setLoadingCurrenInvitation(true);

    const invitationRef = query(collection(db, paths.invitations), where("userId", "==", userId));

    const unsubscribeInvitation = onSnapshot(invitationRef, async () => {
      const invitations = await getDocuments(invitationRef) as Invitation[];
      setUserInvitations(invitations);
      if (user && user.activeClub) {
        const invitation = invitations.find(invitation => user.activeClub === invitation.clubId);
        if (invitation) {
          setCurrentInvitation({...user, ...invitation})
        }
      }
      setLoadingCurrenInvitation(false)
    })
    
    addSubscription(unsubscribeInvitation);

    return () => {
      unsubscribeInvitation();
    };
  }, [user, userId]);

  return <UserContext.Provider value={{ user, loadingCurrenInvitation, loadingUser, currentInvitation, userInvitations }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
