import { Club, emptyMatch, Invitation, Match, MatchInvite, matchType, Referee } from "@monorepo/shared";

export const generateCompensation = ( settings: Club["settings"], periodLength?: number) => {
  if (!periodLength) {
    return undefined
  }
  return settings.matchTypes[periodLength.toString()]?.compensation;
}

export const generateReferee = (referees: Referee[], refereeStats: {[id: string]: number} , match: Match) => {
  const refereesWithPermission: Referee[] = referees.filter((referee) => (referee.permissions || []).includes(match.periodLength || 0))
  if (refereesWithPermission.length <= 0) {
    return null
  }  
  const referee = refereesWithPermission
    .reduce((acc, referee) => ((referee.matches || 0) + refereeStats[referee.id!] < (acc.matches || 0) + refereeStats[acc.id!] ? referee : acc));

  return referee;
}

export const generateExampleMatches = (matches: Match[], referees: Referee[], matchInvitations: { [matchId: string]: MatchInvite[]; }, settings: Club["settings"]) => {
  const refereeStats = Object.fromEntries(referees.map(ref => [ref.id!, 0]));
  const newExampleMatches: { [matchId: string]: Match; } = {};
  const newInviteExamples: { [matchId: string]: MatchInvite[]; } = {};

  matches.forEach(match => {
    if (!matchType(match) || !match.id) {
      return;
    }

    const newInvites: MatchInvite[] = (matchInvitations[match.id!] || []).map((invite) => ({...invite, status: invite.status === "invite" || invite.status === "declined" ? null : invite.status}));
    let newInvitesLength = newInvites.filter(invite => invite.status !== null).length;

    newInvites.forEach((invite) => {
      if (invite.status !== null) {
        refereeStats[invite.id!] += 1;
      }
    });

    if ((match.referees || settings.referees || 1) - newInvitesLength <= 0) {
      console.log('No additional referees needed for match:', match.id!);
      return match;
    }

    while ((match.referees || settings.referees || 1) - newInvitesLength > 0) {
      const referee = generateReferee(referees, refereeStats, match);
      console.log(referee)

      if (referee) {
        refereeStats[referee.id!] += 1;
        newInvites.push({refereeId: referee.id!, clubId: match.clubId!, matchId: match.id , name: referee.name, phoneNumber: referee.phoneNumber, status: "invite"});
      }
      newInvitesLength += 1;
    }

    newInviteExamples[match.id] = newInvites;
    newExampleMatches[match.id] = {...match, compensation: generateCompensation(settings!, match.periodLength)}
  })

  return {newExampleMatches, newInviteExamples}
};