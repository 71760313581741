import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Box, Typography, Stack, IconButton, Toolbar, Divider, Link, Chip, Tooltip, Drawer, Tabs, List, Tab, ListItemButton, ListItem, ListItemText, ListItemIcon, LinearProgress, CircularProgress } from '@mui/material';
import { Club, Colors, emptyMatch, filterComing, filterPlayed, formatTime, Invitation, Match, MatchInvite, matchType, paths, Referee, refereeStatus, sortByDate } from '@monorepo/shared';
import { useOutletContext } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { collection, doc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { useAppContext } from 'contexts/AppContext';
import { useMatches } from 'contexts/MatchesContext';



export const TrainerSchedule = () => {
  const {matches, matchInvitations} = useMatches();
  const [coming, setComing] = useState<Match[]>([]);
  const {setTitle, setTabSettings, setTabs} = useAppContext();

  const formatMatches = React.useCallback(() => {
    const upcomingMatches = matches.filter(filterComing).sort(sortByDate);
  
    setComing(upcomingMatches);
  }, [matches])



  useEffect(() => {
    setTitle("Kommande Matcher");
    setTabSettings(null);
    setTabs(null);
  }, [setTitle, setTabSettings, setTabs]);

  useEffect(() => {
    formatMatches();
  }, [matches, formatMatches]);

  const sortedInvites = (invites: MatchInvite[]) => (
    Object.values(invites || {}).sort((a, b) => a.name > b.name ? 1 : -1)
  )

  const displayInviteField = (invites: MatchInvite[], field: "name" | "phoneNumber") => (
    <Stack>
      {sortedInvites(invites).map((invite, i) => (
        <Typography key={i}>
          {invite[field]}
        </Typography>      
      ))}
    </Stack>
  )

  return (
    <Box sx={{ overflow: "hidden", height: "100%", maxHeight: "100%", display: "flex", flexDirection: "column", padding: {md: 2, xs: 0},}}>
      <Paper 
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          maxHeight: "100%",
          height: "100%",
        }}
        elevation={24}
      >
        <TableContainer sx={{ height: "100%", maxHeight: "100%", overflowY: "auto" }} component={Paper}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {["", "Tid", "Plats", "Lag", "Division", "Domare", "Telefon"].map(title => (
                  <TableCell key={title} sx={{ whiteSpace: 'nowrap', bgcolor: Colors.lightGrey}}><span style={{fontWeight: "bolder"}}>{title}</span></TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody >
              {coming.map((match, i) => (
                <TableRow  key={i}>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{i}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatTime(match.time)}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.place}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Stack direction="column">
                      <span>{match.home}</span>
                      <span>{match.away}</span>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.division}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInviteField(matchInvitations[match.clubId!], "name")}</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInviteField(matchInvitations[match.clubId!], "phoneNumber")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};