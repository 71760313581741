import * as React from "react";
import { Stack, Typography, Link, TextField, Button, Box, Autocomplete, Select, MenuItem, FormControl, InputLabel, CircularProgress, LinearProgress, FormGroup, FormControlLabel, Checkbox, Modal, emphasize, IconButton, Alert } from "@mui/material";
import { Colors, Invitation, paths, User as ClubUser, Club } from "@monorepo/shared";
import { getDistricts, getClubs } from "@monorepo/shared";
import {createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, User} from "firebase/auth"
import { auth, db } from "../../../config/firebase/firebase";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { emptyUser, resetPassword, signInUser } from "../utils/user";
import { AuthDrawer } from "./Drawer";


export const SignIn = () => {

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [showResetPassword, setShowResetPassword] = React.useState(false);

  const [errorText, setErrorText] = React.useState("");

  const navigate = useNavigate();
  const handleClick = async () => {
    if (showResetPassword) {
      await resetPassword(email, setErrorText);
    } else {
      if (await signInUser(email, password, setErrorText)) {
        navigate("/home/schedule");
      }
    }
  }

  return (
    <AuthDrawer signIn>
      <Stack role="presentation">
        <TextField  id="mail" label="Mail" variant="standard" value={email} onChange={e => setEmail(e.target.value)}/>
        {!showResetPassword && <TextField 
          fullWidth type='password' 
          onKeyDown={(e) => {e.key === "Enter" && signInUser(email, password, setErrorText)}} 
          value={password} id="password" label="Lösenord" variant="standard" 
          onChange={e => setPassword(e.target.value)}
        />}
        <Button onClick={() => setShowResetPassword(!showResetPassword)}>{showResetPassword ? "Logga In" : "Glömt lösenord?"}</Button>
        {errorText !== "" && 
          <Alert severity="error" >
            {errorText}
          </Alert>
        }
        <Button onClick={handleClick} variant="contained" >{showResetPassword ? "Återställ" : "Logga in"}</Button>
      </Stack>      
    </AuthDrawer>
  )
}