import { Club } from "@monorepo/shared";

type MatchType = {
  uniqueId: string,
  displayValue: string,
  compensation: number,
}

export const getCompensation = (id: string, clubType?: Club["clubType"]) => {
  if (!clubType) {
    return 0
  }
  return matchTypes(clubType).find(mt => mt.uniqueId === id)?.compensation || 0
}

export const getDisplayValue = (id: string, clubType?: Club["clubType"]) => {
  if (!clubType) {
    return ""
  }
  return matchTypes(clubType).find(mt => mt.uniqueId === id)?.displayValue || ""
}


export const matchTypes = (clubType?: Club["clubType"]) => {
  if (clubType === "soccer") {
    return soccerMatchTypes
  }

  return []
}



export const soccerMatchTypes: MatchType[] = ([
  ["180", "3-3", 100],
  ["900", "5-5", 150],
  ["1200", "7-7", 345],
  ["1500", "9-9", 430],
  ["2400", "11-11", 520],
  ["2700", "11-11 förbund", 600],
] as [string, string, number][])
.map(matchType => ({
  uniqueId: matchType[0],
  displayValue: matchType[1],
  compensation: matchType[2],
}));