import { Colors, formatDate, formatTime, Match, MatchInvite, paths } from "@monorepo/shared";
import { Box, Button, Dialog, DialogContent, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";

import 'dayjs/locale/sv'
import { useAppContext } from "contexts/AppContext";
import { Unpaid } from "features/payments/components/Unpaid";

import { Outlet, Link, useLocation } from "react-router-dom";
import { LoadingWrapper } from "shared/components/LoadingWrapper";
import { useMatches } from "contexts/MatchesContext";
import { useReferees } from "contexts/RefereesContext";

export const Payments = () => {
  const { setTabs } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const {loadingMatches} = useMatches();
  const {loadingReferees} = useReferees();

  React.useEffect(() => {
    setTabs(
      <Tabs
        orientation="vertical"
        value={location.pathname === "/home/payments" ? "/home/payments/unpaid" : location.pathname}
        onChange={(e, value) => (navigate(value))}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Obetalt" value="/home/payments/unpaid" sx={{ color: Colors.darkBlue }} />
        <Tab label="Bokföringsrapport" value="/home/payments/accounting" sx={{ color: Colors.darkBlue }} />
        <Tab label="Betalningsrapport" value="/home/payments/report" sx={{ color: Colors.darkBlue }} />
      </Tabs>
    );
  }, [location.pathname, navigate, setTabs]);

  return (
    <Box sx={{ overflow: "hidden", height: "100%", display: "flex", flexDirection: "column", padding: { md: 2, xs: 0 } }}>
      <LoadingWrapper loading={loadingMatches || loadingReferees}>
        <Paper sx={{ overflow: "hidden", height: "100%" }} elevation={24}>
            <Outlet />
        </Paper>
      </LoadingWrapper>
    </Box>
  );
};