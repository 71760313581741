import * as React from 'react';
import { Colors } from "@monorepo/shared"
import { Box, Button, Slide, Stack, Typography, useScrollTrigger } from "@mui/material"
import { ActionCard } from './ActionCard';
import { useNavigate } from 'react-router-dom';
import { getReferralFromUrl } from '../utils/refferal';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SportsIcon from '@mui/icons-material/Sports';
import { useInView } from 'react-intersection-observer';
import { useScreenSize } from '../hooks/screenSize';
import { QuestionDialog } from './Questions';

const IconCard = (icon: React.ReactNode, timeOut: number, inView: boolean, title: string, description: string) => {
  const [trigger, setTrigger] = React.useState(false);

  React.useEffect(() => {
    if (inView) {
      setTimeout(() =>{ 
        setTrigger(true);
      }, timeOut) 
    }
  }, [inView, timeOut]);
  return (
    <Slide direction="up" in={trigger} unmountOnExit={false}>
      <Stack alignItems={"center"} alignSelf={"center"} width={350} >
        <Box>
          {icon}
        </Box>

        <Typography color='primary' fontWeight={"bold"} variant="h3">
          {title}
        </Typography>
        <Typography sx={{color: Colors.black}} variant="h6">
          {description}
        </Typography>
      </Stack>
    </Slide>

  )
}

export const ForClubs = () => {
  const {smallScreen} = useScreenSize();
  const { ref, inView,  } = useInView({threshold: smallScreen ? 0.2 : 0.5});
  return (
    <Stack padding={{xs: 0, md: 10}} textAlign={"center"} justifyContent={"center"} alignItems={"center"} >
      <Typography color="primary" variant={smallScreen ? "h3" : "h2"} paddingTop={5}>
        REFFI -
      </Typography>
      <Typography color="primary" variant={smallScreen ? "h3" : "h2"}>
        FÖR HELA FÖRENINGEN
      </Typography>
      <Stack direction={{md: "row", xs: "column"}} sx={{overflowY: "hidden"}} spacing={{xs: 10}} padding={{xs: 0, md: 10}} width={"100%"} overflow={"auto"} justifyContent={"space-between"} ref={ref}>
        {IconCard(<GroupsIcon sx={{color: Colors.darkBlue, fontSize: 200}}/>, 0, inView, "FÖRENING", "Säg farväl till långa timmar av planering, administration och domarbokning - få mer tid att utveckla föreningen")}
        {IconCard(<SportsIcon sx={{color: Colors.darkBlue, fontSize: 200}}/>, 100, inView,"DOMARE", "Får smidigt alla sina matcher direkt i mobilen och kan därför fokusera mer på att döma.")}
        {IconCard(<AccountCircleIcon sx={{color: Colors.darkBlue, fontSize: 200}}/>, 200, inView,"TRÄNARE", "Full koll på domare för lagets matcher och inget krångel med administration - mer tid att lyfta barn och unga!")}
      </Stack>
      <QuestionDialog />
    </Stack>
  );
};

const PriceCard = (timeOut: number, inView: boolean, title: string, description: string, action: () => void) => {
  const [trigger, setTrigger] = React.useState(false);

  React.useEffect(() => {
    if (inView) {
      setTimeout(() =>{ 
        setTrigger(true);
      }, timeOut) 
    }
  }, [inView, timeOut]);

  return (
    <Slide direction="up" in={trigger} unmountOnExit={false}>
      <Stack alignItems={"center"} justifyContent={"space-between"} alignSelf={"center"} height={300} width={350} spacing={1} padding={4} border={`6px solid ${Colors.primary}`}>
        <Typography color='primary' fontWeight={"bold"} variant="h3">
          {title}
        </Typography>
        <Typography sx={{color: Colors.black}} variant="h5">
          {description}
        </Typography>
        <Button variant="contained" size='large' onClick={action} sx={{padding: 1.5, paddingRight: 4, paddingLeft: 4}}>
          Kom Igång!
        </Button>
      </Stack>
    </Slide>
  )
}

export const Prices = () => {
  const {smallScreen} = useScreenSize();
  
  const { ref, inView,  } = useInView({threshold: smallScreen ? 0.2 : 0.5});
  
  const navigate = useNavigate();

  const handleNavigate = () => {
    const referralCode = getReferralFromUrl();
    const url = referralCode ? `/signup?referral=${referralCode}` : `/signup`;
    navigate(url, { replace: true });
  }

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const handleRedirect = () => {

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.open("https://apps.apple.com/us/app/reffi/id6720720771", "_blank", "noopener,noreferrer");
    } else {
      window.open("https://play.google.com/store/apps/details?id=se.reffi", "_blank", "noopener,noreferrer");
    }

  };

  return (
    <Stack padding={{xs: 0, md: 10}} textAlign={"center"} minHeight={"100vh"}  alignItems={"center"} >
      <Typography variant={smallScreen ? "h3" : "h2"} padding={5} >
        <span style={{ color: Colors.primary}}>PRISER</span>
      </Typography>
      <Stack ref={ref} direction={{md: "row", xs: "column",}} sx={{overflowY: "hidden"}} spacing={{xs: 10}} padding={2} width={"100%"} overflow={"auto"} justifyContent={"space-between"}>
        {PriceCard(0, inView, "FÖRENING", "Föreningskontot kostar och betalas av föreningen", handleNavigate)}
        {PriceCard(100, inView, "DOMARE", "Domarkonton är gratis att skapa", handleRedirect)}
        {PriceCard(200, inView, "TRÄNARE", "Tränarkonton är också helt gratis att skapa", handleNavigate)}
      </Stack>
      <Typography variant="h4" padding={5}>
        <span style={{ color: Colors.black}}>Testa Reffi gratis första månaden - helt utan bindningstid!</span>
      </Typography>
      <QuestionDialog />
    </Stack>
  )
}