import * as React from "react";
import { Stack, Typography, Link, TextField, Button, Box, Autocomplete, Select, MenuItem, FormControl, InputLabel, CircularProgress, LinearProgress, FormGroup, FormControlLabel, Checkbox, Modal, emphasize, IconButton, Alert, Drawer } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { getReferralFromUrl } from "../utils/refferal";


export const AuthDrawer = ({ signIn = false, children}: {signIn?: boolean, children: JSX.Element}) => {
  const navigate = useNavigate();
  const handleNavigate = (page: string) => {
    const referralCode = getReferralFromUrl();
    const url = referralCode ? `/${page}?referral=${referralCode}` : `/${page}`;
    navigate(url, { replace: true });
  }

  return (
    <Stack spacing={2} sx={{ width: 450, maxWidth: "100vw", padding: 5, height: "100vh" }} role="presentation">
      <Button sx={{position: "absolute", right: 1, top: 2}} onClick={() => handleNavigate("")}><CloseIcon/></Button>
      <Typography sx={{ textAlign: 'center' }}>
        {!signIn ? "Har du redan konto? " : "Saknar du konto?"}
        <span>
          <Link component="button" onClick={() => handleNavigate(signIn ? "signup" : "login")}>
            {!signIn ? "Logga in" : " Skapa konto"}
          </Link>
        </span>
      </Typography>
      {children}
    </Stack>
  )
}