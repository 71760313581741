import { DataGrid, GridColDef, GridPrintExportOptions, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import { usePaymentData } from "../hooks/usePaymentData";
import { filterBetweenDates } from "../utils/timeUtils";
import dayjs, { Dayjs,  } from 'dayjs';
import { useEffect, useState } from "react";
import { MatchWithRefereeInfo } from "../types/types";
import { formatTime } from "@monorepo/shared";
import { useAppContext } from "contexts/AppContext";
import { DateTab } from "./DateTab";
import { Box } from "@mui/material";


const columns: GridColDef[] = [
  { field: 'id', headerName: '', width: 20, },
  { field: 'match', headerName: 'Match', flex: 4 },
  { field: 'name', headerName: 'Domare', flex: 1 },
  { field: 'time', headerName: 'Datum', flex: 1.2 },
  { field: 'compensation', headerName: 'Belopp', headerAlign: "right", align: "right", width: 70},
];

export const AccountingReport = () => {
  const {paid} = usePaymentData();
  const {setTabSettings, setTitle} = useAppContext();

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(30, 'day'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());


  const apiRef = useGridApiRef(); 

  useEffect(() => {
    setTitle("Bokföringsrapport");
  }, [setTitle])


  

  useEffect(() => {
    setTabSettings(        
      <DateTab
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        apiRef={apiRef}
      />
    )
  }, [apiRef, endDate, setTabSettings, startDate])
  
  const getRows = () => paid.filter((match) => filterBetweenDates(match, startDate, endDate)).map((match, i) => {
    const paymentDetails = match.paid!;
    return {
      ...match,
      match: match.home + "-" + match.away,
      compensation: paymentDetails.amount + "kr",
      time: formatTime(paymentDetails.date),
      id: i,
    }
  })



  return (
    <Box sx={{ width: '100%', height: "100%" }}>
      <DataGrid
        rows={getRows()}
        disableColumnFilter
        disableColumnMenu
        rowSelection={false}
        columns={columns}
        pageSizeOptions={[5, 10]}
        localeText={
          {noRowsLabel: "Inga betalningar i den angivna tidsperioden", 
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
            },
          }}
        sx={{ border: 0 }}
      />
      
      <div style={{width: "900px"}}>
        <DataGrid
          apiRef={apiRef}
          rows={getRows()}
          rowSelection={false}
          disableColumnFilter
          disableColumnMenu
          columns={columns}
          pageSizeOptions={[5, 10]}
          localeText={
            {noRowsLabel: "Inga betalningar i den angivna tidsperioden", 
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
              },
            }}
          sx={{ border: 0, display: "hidden" }}
        />
      </div>
    </Box>
  )
}