import { Alert, Snackbar } from "@mui/material";
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { SlideTransitionUp } from "shared/components/Slide";

interface AppContextType {
  title: string;
  setTitle: (title: string) => void;
  tabs: ReactNode;
  setTabs: (tabs: ReactNode) => void;
  tabSettings: ReactNode;
  setTabSettings: (settings: ReactNode) => void;
  setStatusMessage: (text: string) => void;
  setOpenStatusMessage: (open: boolean) => void;
  setStatusMessageType: (type: "success" | "info" | "warning" | "error" | undefined) => void
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState("");
  const [tabs, setTabs] = useState<ReactNode>(null);
  const [tabSettings, setTabSettings] = useState<ReactNode>(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [openStatusMessage, setOpenStatusMessage] = useState(false);
  const [statusMessageType, setStatusMessageType] = useState<"success" | "info" | "warning" | "error" | undefined>(undefined);

  useEffect(() => {
    if (!openStatusMessage) {
      setStatusMessageType("success");
    }
  }, [openStatusMessage])

  return (
    <AppContext.Provider value={{ title, tabs, setTabs, setTitle, tabSettings, setTabSettings, setStatusMessage, setOpenStatusMessage, setStatusMessageType }}>
      {children}
      <Snackbar TransitionComponent={SlideTransitionUp} open={openStatusMessage} autoHideDuration={4000} onClose={() => setOpenStatusMessage(false)}>
        <Alert
          onClose={() => setOpenStatusMessage(false)}
          severity={statusMessageType}
          variant="filled"
          sx={{ width: '100%'}}
        >
          {statusMessage}
        </Alert>
      </Snackbar>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};