import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import {  Colors, Invitation, Referee } from "@monorepo/shared";
import { UserTable } from "features/users/components/UserTable";
import { EditReferee } from "features/users/components/EditReferee";
import { useAppContext } from "contexts/AppContext";
import { useUserData } from "../hooks/useUserData";
import { useClub } from "contexts/ClubContext";
import SearchIcon from '@mui/icons-material/Search';


const trainerColumns = ["Namn", "Roll", "Telefon", "Mail"];

export const Trainers = () => {
  const { club } = useClub();
  const {invitedUsers, acceptedUsers, } = useUserData();

  const [selectedUser, setSelectedUser] = useState<Invitation | undefined>(undefined);
  const [userSearch, setUserSearch] = useState("");

  const {setTitle, setTabSettings,} = useAppContext();

  useEffect(() => {
    setTitle("Tränare");
    setTabSettings([]);
  }, [setTabSettings, setTitle])

  return (
    <Box sx={{ overflow: "hidden", height: "100%", maxHeight: "100%", display: "flex", flexDirection: "column", padding: {md: 2, xs: 0},}}>
      <EditReferee 
        selectedUser={selectedUser} 
        removeSelected={() => setSelectedUser(undefined)} 
        clubId={club?.id || ""} 
      />


      <Paper elevation={24}       
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        maxHeight: "100%",
        height: "100%",
        padding: {md: 4}
      }}
      >
      {invitedUsers.length > 0 && (
        <Box sx={{ marginBottom: 5}} >
          <Typography sx={{ whiteSpace: 'nowrap'}}  paddingLeft={3} paddingBottom={5} variant="h5"  >Nya Förfrågningar</Typography>
          <UserTable
            data={invitedUsers}
            columns={trainerColumns}
            onRowClick={(user) => setSelectedUser(user)}
          />
        </Box>
      )}
      <Stack direction={"row"} paddingLeft={3} marginBottom={3} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="h5"  sx={{ whiteSpace: 'nowrap',}} >Tränare</Typography>
        <Stack direction={"row"} spacing={5} justifyContent={"space-between"} alignItems={"center"} display={{xs: "none", md: "flex"}}>
            <Paper
              sx={{ p: '12px 8px', display: 'flex', alignItems: 'center' }}
            >
              <Typography sx={{ whiteSpace: 'nowrap',}} color="textSecondary">{acceptedUsers.length} Tränare</Typography>
            </Paper> 
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',  }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Sök"
                inputProps={{ 'aria-label': 'sök' }}
                value={userSearch}
                onChange={e => setUserSearch(e.target.value)}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>        
      </Stack>
      </Stack>
      {acceptedUsers.length > 0 && <UserTable
          data={acceptedUsers.filter(referee => referee.name.includes(userSearch))}
          columns={trainerColumns}
          onRowClick={(user) => setSelectedUser(user)}
        />}
      </Paper>
    </Box>
  );
};
