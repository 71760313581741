import React, { useState, useEffect, useCallback } from 'react';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import {  Colors, emptyMatch, Match, MatchInvite, } from '@monorepo/shared';
import { useMatchData } from 'features/matches/hooks/useMatchData';
import { useAppContext } from 'contexts/AppContext';
import { MatchEditor } from 'features/matches/components/MatchEditor';
import { ScheduleTable } from 'features/matches/components/ScheduleTable';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LoadingWrapper } from 'shared/components/LoadingWrapper';
import { useMatches } from 'contexts/MatchesContext';
import { useClub } from 'contexts/ClubContext';
import { useUser } from 'contexts/UserContext';

export const Schedule = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {loadingMatches} = useMatches();
  const {loadingClub} = useClub();
  const {loadingCurrenInvitation} = useUser();

  const {setTabs} = useAppContext();

  const [selectedMatch, setSelectedMatch] = useState<Match | undefined>(undefined);
  
  useEffect(() => {
    const pathOptions = ["/home/schedule", "/home/schedule/examples"]
    const currentTabValue =
      pathOptions.includes(location.pathname) ? "/home/schedule/coming" : location.pathname;

    setTabs(      
      <Tabs
        orientation="vertical"
        value={currentTabValue}
        onChange={(e, value) => (navigate(value))}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Kommande" value={"/home/schedule/coming"} sx={{ color: Colors.darkBlue }} />
        <Tab label="Spelade" value={"/home/schedule/played"} sx={{ color: Colors.darkBlue }} />
        <Tab sx={{ display: 'none', color: Colors.darkBlue }} value={"/home/schedule/overview"} label="Överblick" />
      </Tabs>
    ); 
  }, [location.pathname, navigate, setTabs]);

  return (
    <Box sx={{ overflow: "hidden", height: "100%", maxHeight: "100%", display: "flex", flexDirection: "column", padding: {md: 2, xs: 0},}}>
      <Paper 
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          maxHeight: "100%",
          height: "100%",
        }}
        elevation={24}
      >
        <MatchEditor open={selectedMatch !== undefined} match={selectedMatch || emptyMatch()} onClose={() => setSelectedMatch(undefined)}/>
          <Outlet context={{setSelectedMatch}}/>
      </Paper>
    </Box>
  );
};