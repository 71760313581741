import { addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import {createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, User} from "firebase/auth"
import { auth, db } from "../../../config/firebase/firebase";
import {  Colors, Invitation, paths, User as ClubUser, Club } from "@monorepo/shared";
import { FirebaseError } from "firebase/app";
import { getReferralFromUrl } from "./refferal";
import { createClubInFirestore, createInvitationInFirestore, defaultClubSettings } from "shared/utils/clubCreation";

export const emptyUser = () => ({id: "0", clubs: [], phoneNumber: "", email: "", activeClub: "", name: "", settings: {}});
  
export async function resetPassword(email: string, setErrorText: (errorText: string) => void) {
  try {
    await sendPasswordResetEmail(auth, email);
    setErrorText("Skickade återställningslänk till: " + email)
  } catch {
    setErrorText("Konto med den e-posten finns inte")
  }
}

export async function signInUser(email: string, password: string, setErrorText: (errorText: string) => void ) {
    try {
      if(!email || !password) return
      await signInWithEmailAndPassword(auth, email, password)
      return true;
    } catch (error) {
      setErrorText("Fel lösenord eller e-post")
      console.log("Error signing in: ", error);
      return false;
    }
}
  
export const userFilledOut = (user: ClubUser, integrity = true, generalPolicy = true) => (
  user.name !== "" && user.email !== "" && user.phoneNumber !== "" && integrity && generalPolicy
)

export async function signUp(clubUser: ClubUser, password: string, setErrorText: (errorText: string) => void) {
  if (!userFilledOut(clubUser)) {    
    console.log("User is missing fields")  
    return;
  }
  try {
    const user: User = (await createUserWithEmailAndPassword(auth, clubUser.email, password)).user;

    const referral = getReferralFromUrl()
    if (referral) {
      clubUser.referral = referral;
    }
    clubUser.id = user.uid;
    await setDoc(doc(db, paths.clubUsers, user.uid), clubUser);
    return true;

  } catch (error) {
    if (error instanceof FirebaseError) {
      if (error.code === "auth/email-already-in-use") {
        setErrorText("E-post används redan, Logga in eller kontakta oss!");
      } else {
        setErrorText("Saknar eller inkompletta uppgifter")
      }
    }
    console.error("Error Joinging or creating Club: ", error);
    return false;
  }
}

export function isweakPassword(password: string) {
  if (password.length < 8) return "Måste vara 8 bokstäver eller längre";
  return ""
}