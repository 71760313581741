import { DocumentData, DocumentReference, getDoc, getDocs, Query } from "firebase/firestore";



export async function  getDocuments(ref: Query<DocumentData, DocumentData>) {
  const data = await getDocs(ref);
  return data.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
  }));
}

export async function getDocument(ref: DocumentReference<DocumentData, DocumentData>) {
  const doc = await getDoc(ref);
  if (!doc.exists()) {
      return null;
  }
  return ({
      id: doc.id,
      ...doc.data()
  });
}