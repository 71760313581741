import { InvitationStatus, UserRole } from "@monorepo/shared";

export const roleToText = (role: UserRole, status: InvitationStatus) => {
  switch (status) {
    case "invited":
      return "Väntar på acceptering";
    case "denied":
      return "Nekad";
  }
  switch (role) {
    case "owner":
      return "Ägare";
    case "responsible":
      return "Domaransvarig";
    default:
      return "Tränare"
  }
}