import React from "react";
import { Colors, filterComing, Match, MatchInvite, paths, Referee, refereeStatus } from "@monorepo/shared";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fade, FormControl, Grid2, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/sv'
import dayjs from 'dayjs';
import { TimePicker } from "@mui/x-date-pickers";
import { collection, deleteDoc, doc,  setDoc} from "firebase/firestore";
import { statusToTile } from "../utils/refereeStatus";
import { refereeIcon } from "./DisplayReferee";
import { db } from "config/firebase/firebase";
import { useAppContext } from "contexts/AppContext";
import { useClub } from "contexts/ClubContext";
import { useMatches } from "contexts/MatchesContext";
import { useReferees } from "contexts/RefereesContext";

export const style = {
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -30%)',
  boxShadow: 24,
  padding: "3%",  
};

export const MatchEditor = ({ match, open, onClose}: {match: Match, open: boolean, onClose: () => void}) => {
  const [updatedMatch, setUpdatedMatch] = React.useState(match);
  const [updatedInvites, setUpdatedInvites] = React.useState<{[refereeId: string] : MatchInvite}>({});
  const [openDelete, setOpenDelete] = React.useState(false);
  const [refereeSearch, setRefereeSearch] = React.useState("");

  const {setStatusMessage, setOpenStatusMessage, setStatusMessageType} = useAppContext();
  const {club} = useClub();
  const {referees} = useReferees();
  const {matchInvitations} = useMatches();

  React.useEffect(() => {
    setUpdatedMatch(match);
    setOpenDelete(false);
  }, [match, setUpdatedMatch])

  React.useEffect(() => {
    if (!match.id || !match) {
      return
    }
    setUpdatedInvites((matchInvitations[match.id] || []).reduce((acc, invite) => {
      acc[invite.refereeId] = invite;
      return acc
    }, {} as {[refereeId: string] : MatchInvite}))
  }, [match, match.id, matchInvitations])

  const handleInputChange = (value: string | number | {[k: string]: MatchInvite}, field: keyof Match) => {
    setUpdatedMatch(prev => ({...prev!, [field] : value}))
  };

  const updateReferee = (referee: Referee, status: refereeStatus | null) => {
    if (!referee.id || !club?.id) return
     
    const statusIndices: (refereeStatus)[] = filterComing(updatedMatch) ? [null, 'invite'] : [null, 'done'];
    const statusIndex = statusIndices.indexOf(status ? status : null) + 1;
    const nextStatus = statusIndices[statusIndex % statusIndices.length]

    const newInvite = {
      name: referee.name,
      refereeId: referee.id,
      clubId: club?.id,
      matchId: updatedMatch.id,
      phoneNumber: referee.phoneNumber,
      status: nextStatus,
    }

    setUpdatedInvites({...updatedInvites, [referee.id]: newInvite});
  };

  
  const saveMatch = async () => {
    if (!club?.id) return;
    try {
      const savedMatch = {...updatedMatch}
      let matchRef;
      if (!updatedMatch.id) {
        matchRef = doc(collection(db, paths.matches));
        savedMatch.id = matchRef.id;
        savedMatch.clubId = club?.id;
      } else {
        matchRef = doc(db, paths.matches, updatedMatch.id.toString());
      }
      await setDoc(matchRef, savedMatch);
      Object.values(updatedInvites).forEach(async invite => {
        const inviteRef = doc(db, paths.matchInvites, `${invite.matchId!.toString()}_${invite.refereeId}`)
        if (invite && invite.status === null) {
          console.log(invite.id)
          try {
            await deleteDoc(inviteRef);
          } catch (error) {
            console.error("Document deletion failed or document already deleted", error);
          }
        } else {
          await setDoc(inviteRef, invite);
        }

      })
      setStatusMessage("Match sparad!");
    } catch {
      setStatusMessage("Misslyckades att spara match!");
      setStatusMessageType("error");
    } finally {
      onClose();
      setOpenStatusMessage(true);
    }
  }

  const removeMatch = async () => {
    if (club?.id && updatedMatch.id) {
      try {
        const matchRef = doc(db, paths.matches, updatedMatch.id.toString());
        deleteDoc(matchRef)        
        setStatusMessage("Match borttagen!");
      } catch {
        setStatusMessage("Misslyckades med att ta bort match!");
        setStatusMessageType("error");
      } finally {
        onClose();
        setOpenStatusMessage(true);
      }
    }
    onClose()
  }

  return (
    <Modal disableAutoFocus={true} closeAfterTransition open={open} onClose={onClose}>
        <Fade in={open}>
          <Paper sx={style} >
            <Stack sx={{flexDirection: {xs: "column", md: "row"}}} width={"100%"}>
              <Box>
                <Grid2 container spacing={2} rowSpacing={4}>
                  <Grid2 size={16}>
                  <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    <span >{!match.id ? "Ny match" : "Uppdatera Match"}</span>
                  </Typography>          
                  </Grid2>  
                  <Grid2 size={6}>
                    <TextField fullWidth label="Hemmalag" value={updatedMatch.home} onChange={(e) => handleInputChange(e.target.value, "home")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField fullWidth label="Bortalag" value={updatedMatch.away} onChange={(e) => handleInputChange(e.target.value, "away")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField fullWidth label="Division" value={updatedMatch.division} onChange={(e) => handleInputChange(e.target.value, "division")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField fullWidth label="Plats" value={updatedMatch.place} onChange={(e) => handleInputChange(e.target.value, "place")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                      <DatePicker
                        sx={{width: "100%"}}
                        label="Datum"
                        value={updatedMatch.time ? dayjs(updatedMatch.time) : null}
                        onChange={(value) => handleInputChange(value?.toISOString()!, "time")}
                        />
                  </Grid2>
                  <Grid2 size={6}>
                    <TimePicker
                      sx={{width: "100%"}}
                      label="Tid"
                      onChange={(value) => handleInputChange(value?.toISOString()!, "time")}
                      value={updatedMatch.time ? dayjs(updatedMatch.time) : null}
                      />
                  </Grid2>
                  <Grid2 size={4}>
                    <TextField fullWidth label="Kompensation (kr)" value={updatedMatch.compensation || 0} type="number" inputMode="numeric" onChange={(e) => handleInputChange(parseInt(e.target.value) >= 0 ? e.target.value : 0, "compensation")}></TextField>
                  </Grid2>                  
                  <Grid2 size={4}>
                    <TextField  fullWidth label="Antal domare" value={updatedMatch.referees || 1} type="number" onChange={(e) => handleInputChange(parseInt(e.target.value) >= 1 ? e.target.value : 1, "referees")}></TextField>
                  </Grid2>
                  <Grid2 size={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="matchType-label">Match typ</InputLabel>
                      <Select
                        fullWidth
                        id="matchType"
                        value={updatedMatch.periodLength || 0}
                        label="Matchtyp"
                        placeholder="Välj match typ"
                        labelId="matchType-label"
                        onChange={(e) => handleInputChange(e.target.value, "periodLength")}
                        >
                        {Object.entries(club?.settings.matchTypes || {}).map(([id, mt]) => 
                          <MenuItem key={id} value={parseInt(id)}>{mt.displayValue}</MenuItem>
                        )}
                      </Select>  
                    </FormControl>                
                  </Grid2>
                  <Grid2 size={12}>
                    <TextField  
                      fullWidth 
                      label="Kommentar till domare" 
                      value={match.comment || ""}
                      onChange={e => handleInputChange(e.target.value, "comment")}
                      multiline    
                    />
                  </Grid2>
                </Grid2>

                <Stack alignSelf={"center"} justifyContent={"space-between"} spacing={3} direction={"row"} marginTop={2}>
                  <Dialog
                    open={openDelete}
                    onClose={() => setOpenDelete(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Radera Match"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Tar bort en match, går inte att ångra. 
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenDelete(false)} autoFocus>Avbryt</Button>
                      <Button onClick={removeMatch}> Radera </Button>
                    </DialogActions>
                  </Dialog>
                  <Button variant="contained" color="error" disabled={!updatedMatch.id} onClick={() => setOpenDelete(true)}>
                    Radera
                  </Button>
                  <Stack alignSelf={"center"} justifyContent={"center"} spacing={3} direction={"row"} margin={2}>
                    <Button variant="contained" onClick={onClose}>
                      Avbryt
                    </Button>
                    <Button variant="contained" onClick={saveMatch}>
                      Spara
                    </Button>
                  </Stack>
                </Stack>
              </Box>
              <Divider orientation="vertical" flexItem sx={{margin: 2, bgcolor: Colors.primary}}/>
              <Box width="100%">
                <TextField variant="standard" label="Domare" fullWidth onChange={(e) => setRefereeSearch(e.target.value)} value={refereeSearch}></TextField>
                <List sx={{overflowY: "auto", maxHeight: {xs: "150px", md: "400px"}}}>
                  {referees.filter(referee => referee.name.toLocaleLowerCase().includes(refereeSearch.toLocaleLowerCase())).map(referee => {
                    const defaultReferee = updatedInvites[referee.id!] || null;
                    return (
                      <ListItem
                        divider 
                        key={referee.id}
                        onPointerDown={() => updateReferee(referee, defaultReferee ? defaultReferee.status : null)} 
                      >
                        <Tooltip placement="right" title={statusToTile(defaultReferee?.status || null, filterComing(match))} key={referee.name}>

                          <Stack direction={"row"} width={"100%"} sx={{justifyContent: "space-between", flexDirection: 1}} alignItems={"center"} >
                            <ListItemText>{referee?.name || ""}</ListItemText>
                            <ListItemIcon sx={{justifyContent: "flex-end"}}>{defaultReferee ? refereeIcon(defaultReferee.status, filterComing(updatedMatch)) : ""}</ListItemIcon>
                          </Stack>
                        </Tooltip>
                      </ListItem>
                    )
                  })} 
                </List>
              </Box>
            </Stack>
          </Paper>
        </Fade>
    </Modal>
  )
}