import * as React from 'react';
import { Colors } from "@monorepo/shared";
import { Button, ButtonProps, colors, Paper, Stack, styled, Typography } from "@mui/material";
import { useScreenSize } from '../hooks/screenSize';
import { ColorButton } from './ColorButton';
import { ContactDialog } from './Contact';



export const Slogan = () => {
  const { screenHeight, screenWidth } = useScreenSize();

  return (
    <Stack
      sx={{
        minHeight: '100vh', 
        overflow: 'hidden',
      }}
    >
      <img
        src={require("../assets/images/blue_chair_tint.jpg")}
        alt=""
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1, 
        }}
      />
      <Stack direction={{xs: "column", md: "row"}} spacing={4}  marginTop={{xs: 0, md: 15}} zIndex={2}>
        <Stack width={"100%"} alignItems={"center"}  padding={{xs:3, md: 10}}>
          <Stack spacing={2} zIndex={2} alignItems="start">
            <Typography
              fontWeight="bold"
              variant="h3"
              sx={{
                fontSize: { xs: "1.8rem", sm: "2.5rem", md: "3rem" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Lägger Ni För Mycket Tid
            </Typography>
            <Typography
              fontWeight="bold"
              variant="h2"
              sx={{
                fontSize: { xs: "2.2rem", sm: "3rem", md: "4rem" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              På <span style={{ textDecoration: "underline" }}>Domarhanterin</span>g?
            </Typography>
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Oavsett sport är föreningslivet stressigt med repetitiva uppgifter
            </Typography>
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              - istället prioritera det som verkligen betyder något: utvecklingen av barn och unga.
            </Typography>
            <ContactDialog />
          </Stack>
        </Stack>

        <Paper elevation={24} sx={{
          width: "100%",
          backgroundImage: `url(${require("../assets/images/inlogg.jpg")})`,
          backgroundSize: 'cover', // Ensures the image covers the entire container
          backgroundPosition: 'start', // Centers the background image
          position: 'relative', // Needed for the smaller image positioning
          left: 90,
          height: '500px', // Set a fixed height or responsive height
        }}>
          <img
            src={require("../assets/images/Appstore_Screenshots.png")}
            alt=""
            style={{
              height: "550px",
              position: "absolute",
              top: 80, // Position relative to the parent container
              left: -180, // Adjust offset relative to the container
              zIndex: 3,
            }}
          />
        </Paper>
      </Stack>
    </Stack>
  );
};
