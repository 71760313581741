import React, { useState } from "react";
import { Drawer, Divider, Tabs, Tab, IconButton, Typography, Avatar, ButtonBase, styled } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { Colors } from "@monorepo/shared";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

interface SideBarProps {
  open: boolean;
  onClose: () => void;
  drawerWidth: number;
  isClub: boolean,
}

export const Sidebar = ({ open, onClose, drawerWidth, isClub }: SideBarProps) => {
  const { tabSettings, tabs } = useAppContext();
  
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === "/home/schedule/coming") {
      navigate("/");
    } else {
      navigate("/home/schedule/coming");
    }
  }

  const tabValue = () => {
    const currentTab = location.pathname.split("/").slice(0, 3).join("/");
    const options = ["/home/schedule", "/home/users", "/home/payments"];
    return options.includes(currentTab) ? currentTab : "/home/schedule"
  }

  return (
    <Drawer
      elevation={24}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          backgroundColor: Colors.primary,
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <ButtonBase onClick={handleLogoClick}>
          <Avatar src={require("../../assets/images/icon.png")} />
          <Typography variant="h6" sx={{ color: Colors.white, fontStyle: "italic" }}>
            REFFI
          </Typography>
        </ButtonBase>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon color="secondary" />
        </IconButton>
      </DrawerHeader>
      {isClub && <Divider />}
      {isClub && <Tabs
        orientation="vertical"
        value={tabValue()}
        onChange={(e, value) => navigate(value)}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label="Schema" value="/home/schedule" sx={{ color: Colors.darkBlue }} />
        <Tab label="Vår klubb" value="/home/users" sx={{ color: Colors.darkBlue }} />
        <Tab label="Betalningar" value="/home/payments" sx={{ color: Colors.darkBlue }} />
      </Tabs>}
      <Divider />
      {tabs && tabs}
      <Divider />
      {tabSettings && tabSettings}
    </Drawer>
  );
};