import { Colors } from "@monorepo/shared";
import { Button, ButtonProps, styled } from "@mui/material";

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: Colors.primary,
  backgroundColor: Colors.white,
  fontWeight: "bold",
  height: 50,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: Colors.white,
  },
}));