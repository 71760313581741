import { Colors, Invitation, Referee } from "@monorepo/shared";
import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useClub } from "contexts/ClubContext";
import { useReferees } from "contexts/RefereesContext";
import { useUser } from "contexts/UserContext";
import { useEffect } from "react";
import { getDisplayValue } from "shared/utils/defaultMatchTypes";
import { roleToText } from "shared/utils/roles";

export const UserTable = ({
  data,
  columns,
  onRowClick
}: {
  data: Invitation[] | (Invitation & Referee)[],
  columns: string[],
  onRowClick: (item: Invitation) => void
}) => { 

  const {club} = useClub();
  const {referees} = useReferees();

  return (
    <TableContainer sx={{ maxHeight: "100%", overflowY: "auto" }} >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap'}}></TableCell>
            {columns.map((col, index) => 
              <TableCell key={col} align={index === columns.length - 1 ? "right" : "inherit"} sx={{ whiteSpace: 'nowrap',}}>
                <span style={{fontWeight: "bolder"}}>{col}</span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => {
            const referee = referees.find(referee => referee.id === item.userId)!;
            const user = {...item, ...referee}
            return (
              <TableRow key={i} hover onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
                <TableCell>{i}</TableCell>
                <TableCell>{item.name}</TableCell>
                {(user.status === "accepted" || user.role !== "referee") && <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {"permissions" in item ? 
                    (user.permissions || []).map((permission, i) => {
                      const val = getDisplayValue(permission.toString(), club?.clubType);
                      return <Chip key={i} label={val} style={{margin: 2}}/>
                    })
                      :
                    roleToText(user.role, user.status)
                  }
                </TableCell>}
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell align={"accountNumber" in user ? "inherit" : "right"}>{user.email}</TableCell>
                {"socialSecurityNumber" in user && <TableCell align={user.status === "accepted" ? "inherit" : "right"}>{user.socialSecurityNumber}</TableCell>}
                {user.status === "accepted" && "bank" in user &&<TableCell>{user.bank}</TableCell>}
                {user.status === "accepted" && "accountNumber" in user && <TableCell align="right">{user.clearingNumber}-{user.accountNumber}</TableCell>}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
};