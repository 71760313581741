// RefereesContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import { Referee, paths } from '@monorepo/shared';
import { useClub } from './ClubContext';
import { useInvitations } from './InvitationsContext';
import { getDocuments } from 'utils/getDocs';
import { addSubscription } from "./ManageSubscriptions";
import { useUser } from './UserContext';

interface RefereesState {
  referees: Referee[];
  loadingReferees: boolean;
}

const RefereesContext = createContext<RefereesState | undefined>(undefined);

export const RefereesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { club } = useClub();
  const {currentInvitation} = useUser();
  const { invitations } = useInvitations();
  const [referees, setReferees] = useState<Referee[]>([]);
  const [loadingReferees, setLoadingReferees] = useState(false);

  useEffect(() => {
    if (!club || club.id === "" || (currentInvitation?.role || "trainer") === "trainer") return;

    setLoadingReferees(true);
    const refereeRef = query(collection(db, paths.referees), where('clubs', 'array-contains', club.id));

    const unsubscribe = onSnapshot(refereeRef, async () => {
      const fetchedReferees = await getDocuments(refereeRef) as Referee[];
      const enrichedReferees = fetchedReferees.map((referee) => {
        const invitation = invitations.find((invite) => invite.userId === referee.id);
        return { ...referee, ...invitation };
      }).filter(referee => referee.status === "accepted");
      setReferees(enrichedReferees);
      setLoadingReferees(false);
    });

    addSubscription(unsubscribe)

    return () => unsubscribe();
  }, [club, currentInvitation?.role, invitations]);

  return <RefereesContext.Provider value={{ referees, loadingReferees }}>{children}</RefereesContext.Provider>;
};

export const useReferees = () => {
  const context = useContext(RefereesContext);
  if (!context) {
    throw new Error('useReferees must be used within a RefereesProvider');
  }
  return context;
};
