import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { usePaymentData } from "../hooks/usePaymentData";
import { filterBetweenDates } from "../utils/timeUtils";
import dayjs, { Dayjs,  } from 'dayjs';
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "contexts/AppContext";
import { DateTab } from "./DateTab";
import { Box } from "@mui/material";

const columns: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'name', headerName: 'Namn', flex: 2 },
  { field: 'time', headerName: 'Datum', flex: 1 },
  { field: 'accountNumber', headerName: 'Bank nummer', flex: 1 },
  { field: 'compensation', headerName: 'Belopp', headerAlign: "right",align: "right", width: 80},
];


export const PaymentReport = () => {

  const {paid} = usePaymentData();
  const {setTabSettings, setTitle} = useAppContext();

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(30, 'day'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const apiRef = useGridApiRef(); 
  const wrapperRef = useRef(); 


  useEffect(() => {
    setTitle("Betalningsrapport");
  }, [setTitle])

  useEffect(() => {
    setTabSettings(        
      <DateTab
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        apiRef={apiRef}
      />
    )
  }, [apiRef, endDate, setTabSettings, startDate])

  const getRows = () => paid.filter((match) => filterBetweenDates(match, startDate, endDate)).map((match, i) => {
    const paymentDetails = match.paid!;
    return {
      ...match,
      compensation: paymentDetails.amount + "kr",
      id: i,
    }
  })

  return (
    <Box sx={{ width: '100%', height: "100%" }} ref={wrapperRef}>
      <DataGrid
        rows={getRows()}
        rowSelection={false}
        disableColumnFilter
        disableColumnMenu
        columns={columns}
        pageSizeOptions={[5, 10]}
        localeText={
          {noRowsLabel: "Inga betalningar i den angivna tidsperioden", 
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
            },
          }}
        sx={{ border: 0 }}
      />

      <div style={{width: "900px"}}>
        <DataGrid
          apiRef={apiRef}
          rows={getRows()}
          rowSelection={false}
          disableColumnFilter
          disableColumnMenu
          columns={columns}
          pageSizeOptions={[5, 10]}
          localeText={
            {noRowsLabel: "Inga betalningar i den angivna tidsperioden", 
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
              },
            }}
          sx={{ border: 0, display: "hidden" }}
        />
      </div>
    </Box>
  )
}