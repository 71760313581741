export var sortByDate = function (a, b) {
    var dateA = new Date(a.time);
    var dateB = new Date(b.time);
    if (isNaN(dateA.getTime())) {
        return 1; // If a.time is invalid, sort it to the end
    }
    if (isNaN(dateB.getTime())) {
        return -1; // If b.time is invalid, sort it to the end
    }
    return dateA.getTime() - dateB.getTime();
};
export var formatTime = function (date) {
    var dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
        return "Invalid Date";
    }
    var month = String(dateObj.getMonth() + 1).padStart(2, '0');
    var day = String(dateObj.getDate()).padStart(2, '0');
    var time = dateObj.toTimeString().slice(0, 5);
    return "".concat(month, "/").concat(day, " - ").concat(time);
};
export var formatMonth = function (month) { return [
    "Januari", "Feburari", "Mars", "April", "Maj", "Juni",
    "Juli", "Agusti", "September", "Oktober", "November", "December"
][month] || ""; };
export var formatDate = function (dateString) {
    var date = new Date(dateString);
    return "".concat(date.getDate(), " ").concat(formatMonth(date.getMonth()), " ").concat(date.getFullYear());
};
export var filterComing = function (match) { return ((!match.time) || new Date(match.time) >= new Date()); };
export var filterPlayed = function (match) { return (match.time && new Date(match.time) < new Date()); };
export var formatDateToISO = function (date) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    var hours = String(date.getHours()).padStart(2, '0');
    var minutes = String(date.getMinutes()).padStart(2, '0');
    var seconds = String(date.getSeconds()).padStart(2, '0');
    return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds);
};
export var formatDateBlank = function (date) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    return [year, month, day];
};
