import React, { useState, useEffect, useCallback } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import {  Colors, emptyMatch, Match, MatchInvite, } from '@monorepo/shared';
import { useMatchData } from 'features/matches/hooks/useMatchData';
import { useAppContext } from 'contexts/AppContext';
import { MatchEditor } from 'features/matches/components/MatchEditor';
import { ScheduleTable } from 'features/matches/components/ScheduleTable';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useOutletContext } from 'react-router-dom';
import { useMatches } from 'contexts/MatchesContext';

export const Played = () => {
  const {played} = useMatchData();
  const {matchInvitations} = useMatches();
  const {setTitle, setTabSettings} = useAppContext();
  const {setSelectedMatch} = useOutletContext<{setSelectedMatch: (match: Match) => void}>();

  useEffect(() => {
    setTitle("Spelade matcher");
    setTabSettings(
      <List>
        <ListItem>
          <ListItemButton component="button" onClick={() => setSelectedMatch(emptyMatch())}>
            <ListItemIcon>
              <AddRoundedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Ny Match" sx={{ textAlign: 'center', color: Colors.white }} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }, [setSelectedMatch, setTabSettings, setTitle])

  return (
    <ScheduleTable matches={played} matchInvitations={matchInvitations} onSelectMatch={(match) => setSelectedMatch(match)}/>
  );
};