import { Box, Button, Collapse, Divider, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase/firebase";
import { paths } from "@monorepo/shared";
import { useClub } from "contexts/ClubContext";


interface QuestionsProps {
  
}
export const Questions = ({}: QuestionsProps) => {

  const {club} = useClub();

  const [activeQuestion, setActiveQuestion] = useState<number | null>(null);
  const [questions, setQuestions] = useState<{awnser: string, question: string}[]>([])
  const [newQuestion, setNewQuestion] = useState("");


  const reset = useCallback(() => {
    setQuestions(club?.settings.questions || [])
  }, [club])

  useEffect(() => {
    reset()
  }, [reset])

  const handleClick = (index: number) => {
    setActiveQuestion(index === activeQuestion ? null : index)
  }

  const handleAwnserChange = (index: number, text: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].awnser = text;
    setQuestions(updatedQuestions)
  }

  const removeQuestion = async (index: number) => {

    const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
    if (!club || !clubRef) return;
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    await updateDoc(clubRef, {
      "settings.questions": updatedQuestions,
    });
    if (newQuestion === "") {
      return
    }
  }

  const addQuestion = async () => {
    if (newQuestion === "") {
      return
    }
    const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
    if (!club || !clubRef) return;

    await updateDoc(clubRef, {
      "settings.questions": [...questions, {question: newQuestion, awnser: ""}],
    });

    setNewQuestion("");
  }

  const saveSettings = async () => {
    const clubRef = club ? doc(db, paths.clubs, club.id!) : null;
    if (!club || !clubRef) return;


    await updateDoc(clubRef, {
      "settings.questions": questions,
    });
  }

  
  return (
    
    <Box padding={2} >
      <Typography variant="h5" padding={3}>
        Vanliga frågor om klubben. Frågor och svar kan domaren se i appen. 
      </Typography>

      <Box padding={2}>
      {questions.map(({question, awnser}, index) => (
        <Box key={index}>
          <ListItemButton onClick={() => handleClick(index)}>
            <ListItemIcon>
            </ListItemIcon>
            <ListItemText primary={question} />
            {index === activeQuestion ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse sx={{pl: 12, pr: 8,}} in={index === activeQuestion} timeout="auto" unmountOnExit>
            <Box padding={1} >
              <TextField value={awnser} onBlur={saveSettings} onChange={(e) => handleAwnserChange(index, e.target.value)} label={"Svar"} rows={4} multiline fullWidth></TextField>
            </Box>
            <Box padding={1} justifySelf={"flex-end"}>
              <Button color="error" variant="contained" onClick={() => removeQuestion(index)}>Radera</Button>
            </Box>
          </Collapse> 
        </Box>
      ))}
      </Box>
      <Divider />
      <Box padding={3} alignItems={"center"}>
        <TextField fullWidth label={"Ny fråga"} onChange={e => setNewQuestion(e.target.value)} value={newQuestion}></TextField>
        <Button fullWidth onClick={addQuestion}>Lägg till fråga</Button>
      </Box>
    </Box>
  )
}