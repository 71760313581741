import React from 'react';
import { CircularProgress, Paper, Skeleton, Typography } from '@mui/material';

interface LoadingWrapperProps {
  loading: boolean;
  noAccessMessage?: React.ReactNode;
  children: React.ReactNode;
  skeleton?: boolean,
}

export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ loading, noAccessMessage, children, skeleton }) => {
  if (loading) {
    return (
      <Paper sx={{ flex:1, width: '100%', height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} variant="outlined">
        {skeleton? 
          <Skeleton variant="rounded" width={"100%"} height={"100%"} />
        :
          <CircularProgress />}
      </Paper>
    );
  }
  if (noAccessMessage) {
    return (
      <Paper sx={{ flex:1, width: '100%', height: "100%", display: "flex", justifyContent: "center", alignItems: "center", }} variant="outlined">
        <Typography>{noAccessMessage || 'Access Denied'}</Typography>
      </Paper>
    );
  }

  return <>{children}</>;
};