import React from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Toolbar, IconButton, Typography, Menu, MenuItem, styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "config/firebase/firebase";
import { clearSubscriptions } from "contexts/ManageSubscriptions";


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

interface TopBarProps {
  open: boolean;
  onOpen: () => void;
  drawerWidth: number;
  setOpenSettings: () => void;
  clubName?: string;
  userName?: string;
}

export const TopBar = ({ open, onOpen, drawerWidth, setOpenSettings, clubName, userName }: TopBarProps) => {
  const { title } = useAppContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" open={open} drawerWidth={drawerWidth}>
      <Toolbar>
        <IconButton color="inherit" edge="start" onClick={onOpen} sx={{ display: open ? "none" : "block" }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Typography noWrap component="div" sx={{paddingLeft: 2, flexShrink: 1, display: {xs:  'none',sm: 'block',}}}>
          {clubName || ""}
        </Typography>  
        <IconButton size="large" aria-label="account" onClick={handleMenu} color="inherit">
          <AccountCircle />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem><AccountCircle /> <Typography paddingLeft={1}>{userName || ""}</Typography></MenuItem>
          <MenuItem onClick={() => {handleClose(); setOpenSettings();}}>Inställningar</MenuItem>
          <MenuItem onClick={() => {navigate("/help")}}>Hjälp</MenuItem>
          <MenuItem onClick={() => {clearSubscriptions(); signOut(auth)}}>Logga ut</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
