import { useState, useEffect, useCallback } from 'react';
import {Invitation, Referee } from '@monorepo/shared';
import { useReferees } from 'contexts/RefereesContext';
import { useInvitations } from 'contexts/InvitationsContext';


export const useUserData = () => {
  const { referees } = useReferees();
  const {invitations} = useInvitations();

  const [invitedReferees, setInvitedReferees] = useState<Invitation[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<Invitation[]>([]);
  const [acceptedUsers, setUsers] = useState<Invitation[]>([]);
  const [acceptedReferees, setReferees] = useState<(Invitation & Referee)[]>([]);


  useEffect(() => {
    const userInvitations = invitations.filter(user => user.role !== "referee");
    const refereeInvitations = invitations.filter(user => user.role === "referee");
    setUsers(userInvitations.filter(user => user.status === "accepted"));
    const refereesFullData = refereeInvitations
      .filter(user => user.status === "accepted")
      .map(user => {
        const referee = referees.find(referee => referee.id === user.userId)!;
        return {...user, ...referee}
      });
    setReferees(refereesFullData);
    setInvitedUsers(userInvitations.filter(user => user.status === "invited"));
    setInvitedReferees(refereeInvitations.filter(user => user.status === "invited"));

  }, [invitations, referees])

  return { invitedReferees, invitedUsers, acceptedReferees, acceptedUsers };
};