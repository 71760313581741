import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { usePaymentData } from "../hooks/usePaymentData";
import { useCallback, useEffect, useState } from "react";
import { Colors, formatTime, MatchInvite, paths } from "@monorepo/shared";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase/firebase";
import { useAppContext } from "contexts/AppContext";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { useClub } from "contexts/ClubContext";

const columns: GridColDef[] = [
  { field: 'id', headerName: '', width: 30, },
  { field: 'name', headerName: 'Namn', width: 200 },
  { field: 'phone', headerName: 'Telefon', flex: 1 },
  { field: 'time', headerName: 'Datum', flex: 1 },
  { field: 'division', headerName: 'Division', flex: 2 },
  { field: 'match', headerName: 'Match', flex: 2 },
  { field: 'accountNumber', headerName: 'Bank nummer', flex: 1 },
  { field: 'compensation', headerName: 'Ersättning',  headerAlign: "right",align: "right", flex: 1},
];

interface UnpaidProps {

}

export const Unpaid = () => {
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const {club} = useClub();
  const {unpaid} = usePaymentData();
  const {setTabSettings, setTitle, setStatusMessage, setOpenStatusMessage, setStatusMessageType} = useAppContext();

  const [showFile, setShowFile] = useState(false);

  useEffect(() => {
    setTitle("Obetalt");
  }, [setTitle])

  const markAsPaid = useCallback(() => {
    if (!club) return;
    try {
      const matchInviteRef = collection(db, paths.matchInvites);
  
      selectedRows.forEach(async (selectedRowId) => {
        const matchInvite = unpaid[Number(selectedRowId)];
        await updateDoc(doc(matchInviteRef, matchInvite.id?.toString()), {
          ...matchInvite as MatchInvite,
          status: 'paid',
          paid: {
            amount: matchInvite.compensation! || 0,
            date: new Date().toISOString(),
          },
        });
      });
  
      setSelectedRows([]);
      setStatusMessage("Domare markade som betalda!");
    } catch {
      setStatusMessage("Misslyckades att markera domare!");
      setStatusMessageType("error");
    } finally {
      setOpenStatusMessage(true);
    }
  }, [club, selectedRows, setStatusMessage, unpaid, setStatusMessageType, setOpenStatusMessage]);

  useEffect(() => {
    setTabSettings(        
    <div>
      <ListItem>
        <Tooltip title="Kommer snart">
          <ListItemButton component="button" onClick={() => setShowFile(true)}>
            <ListItemIcon><AutorenewIcon color="secondary" /></ListItemIcon>
            <ListItemText primary="Generera BankFil" sx={{ textAlign: "center", color: Colors.white }} />
          </ListItemButton>
        </Tooltip>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={markAsPaid} component="button">
          <ListItemIcon><ChecklistIcon color="secondary" /></ListItemIcon>
          <ListItemText primary="Markera som betalda" sx={{ textAlign: "center", color: Colors.white }} />
        </ListItemButton>
      </ListItem>
    </div>)
  }, [markAsPaid, setTabSettings])

  const getRows = () => unpaid.map((match, i) => (
    {
      ...match,
      time: formatTime(match.time),
      compensation: match.compensation + "kr",
      match: match.home + "-" + match.away,
      id: i,
    }
  ))

  return (
    <DataGrid
      rows={getRows()}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      pageSizeOptions={[5, 10]}
      checkboxSelection={true}
      onRowSelectionModelChange={(newSelection) => {
        setSelectedRows(newSelection);
      }}
      localeText={
      {noRowsLabel: "Inga obetalda domare", 
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) =>
            `${from}-${to} av ${count === -1 ? `mer än ${to}` : count}`,
        },
      }}
      sx={{ border: 0 }}
    />
  )
}