
import { formatDate } from "@monorepo/shared";
import { Box, Button, Popover } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import React from "react";
import dayjs, { Dayjs,  } from 'dayjs';


interface DatePopUpProps {
  date: Dayjs | null;
  setDate: (date: Dayjs | null) => void;
  title: [start: string, end: string];

}

export const DatePopUp = ({date, setDate, title}: DatePopUpProps) => {

  const [anchorElStart, setAnchorElStart] = React.useState<null | HTMLElement>(null);
  const openStartPopover = Boolean(anchorElStart);

  return (
    <Box width={"100%"}>
      <Button
        variant="contained"
        fullWidth
        onClick={(event) => setAnchorElStart(event.currentTarget)}
        >
        {date ? `${title[0]} ${formatDate(date.toDate().toISOString())}` : title[1]}
      </Button>
      <Popover
        open={openStartPopover}
        anchorEl={anchorElStart}
        onClose={() => setAnchorElStart(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateCalendar
          value={date}
          onChange={(newDate) => {
            setDate(newDate);
            setAnchorElStart(null);
          }}
        />
      </Popover>
    </Box>
  )
}