
import * as React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useScreenSize } from '../hooks/screenSize';
import { Colors } from '@monorepo/shared';
import { getReferralFromUrl } from '../utils/refferal';
import { useNavigate } from 'react-router-dom';


export const AboutUs = () => {
  const navigate = useNavigate();
  const {smallScreen} = useScreenSize();


  const handleNavigate = (page: string) => {
    const referralCode = getReferralFromUrl();
    const url = referralCode ? `/${page}?referral=${referralCode}` : `/${page}`;
    navigate(url, { replace: true });
  }
  return (
    <Box  justifyContent={"center"} alignItems={"center"} padding={{xs: 2, md: 10}}>
      <Stack
        direction={smallScreen ? "column" : "row"}
        justifyContent={"center"}
        spacing={2}
        alignItems={"center"}
      >
        <Box
          flex={1}
          height={"80vh"} 
          sx={{
            backgroundImage: `url(${require("../assets/images/Blue_Chairs.jpg")})`,
            backgroundSize: "cover",
            backgroundPosition: "center", 
            flexShrink: 0, 
          }}
        />

        {/* Text Section */}
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          padding={smallScreen ? 1 : 5}
          width={smallScreen ? "100%" : "50%"}
        >
          <Typography
            gutterBottom
            variant={smallScreen ? "h4" : "h2"}
            component="div"
            sx={{ marginBottom: 0 }}
          >
            <span style={{ color: Colors.primary }}>Av föreningsaktiva.</span>
          </Typography>
          <Typography
            gutterBottom
            variant={smallScreen ? "h4" : "h2"}
            component="div"
            sx={{ marginBottom: smallScreen ? 5 : 10 }}
          >
            <span style={{ color: Colors.primary }}>För föreningsaktiva.</span>
          </Typography>
          <Typography
            component="div"
            variant={smallScreen ? "h6" : "h5"}
            sx={{ color: "text.secondary", marginBottom: 3 }}
            lineHeight={1.2}
          >
            <span style={{ color: Colors.darkBlue }}>
              Reffi är utvecklat av föreningsaktiva och domare för att förenkla
              arbetet med att driva föreningsverksamhet.
            </span>
          </Typography>
          <Typography
            component="div"
            variant={smallScreen ? "h6" : "h5"}
            sx={{ color: "text.secondary", marginBottom: 3 }}
            lineHeight={1.2}
          >
            <span style={{ color: Colors.darkBlue }}>
              Vi strävar efter att digitalisera och automatisera viktiga delar av
              den dagliga verksamheten, med fokus på bokning, schemaläggning och
              utbetalningar till domare.
            </span>
          </Typography>
          <Typography
            component="div"
            variant={smallScreen ? "h6" : "h5"}
            sx={{ color: "text.secondary", marginBottom: 10 }}
            lineHeight={1.2}
          >
            <span style={{ color: Colors.darkBlue }}>
              Reffi strävar efter att bli utformat för alla typer av sporter, både
              gruppidrott och individuella idrotter, och vårt mål är att hjälpa
              föreningar att effektivt hantera och administrera sina
              föreningsdomare.
            </span>
          </Typography>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => handleNavigate("signup")}
            sx={{ borderRadius: 2, alignSelf: "flex-start" }}
          >
            KOM IGÅNG!
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}