import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import { Match, MatchInvite, paths } from '@monorepo/shared';
import { useClub } from './ClubContext';
import { getDocuments } from 'utils/getDocs';
import { addSubscription } from "./ManageSubscriptions";

interface MatchesState {
  matches: Match[];
  matchInvitations: {[matchId: string]: MatchInvite[]};
  loadingMatches: boolean;
}

const MatchesContext = createContext<MatchesState | undefined>(undefined);

export const MatchesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { club } = useClub();
  const [matches, setMatches] = useState<Match[]>([]);
  const [loadingMatches, setLoadingMatches] = useState(true);
  const [matchInvitations, setMatchInvitations] = useState<{[matchId: string]: MatchInvite[]}>({});

  useEffect(() => {
    if (!club || club.id === "") return;

    setLoadingMatches(true);
    const matchRef = query(collection(db, paths.matches), where("clubId", "==", club.id));
    const matchInvitationRef = query(collection(db, paths.matchInvites), where("clubId", "==", club.id));

    const unsubscribeMatches = onSnapshot(matchRef, async () => {
      setMatches(await getDocuments(matchRef) as Match[]);
      setLoadingMatches(false);
    });

    const unsubscribeMatchInvitations = onSnapshot(matchInvitationRef, async () => {
      const invites = await getDocuments(matchInvitationRef) as MatchInvite[];
      const invitesMap = invites.reduce((acc, val) => {
        const previous: MatchInvite[] = acc[val.matchId!] || [];
        previous.push(val);
        return {...acc, [val.matchId!]: previous}
      
      }, {} as {[matchId: string] : MatchInvite[]});
      setMatchInvitations(invitesMap);
      setLoadingMatches(false); 
    });

    addSubscription(unsubscribeMatchInvitations);
    addSubscription(unsubscribeMatches);

    return () => {
      unsubscribeMatches();
      unsubscribeMatchInvitations();
    };

  }, [club]);

  return <MatchesContext.Provider value={{ matches, loadingMatches, matchInvitations }}>{children}</MatchesContext.Provider>;
};

export const useMatches = () => {
  const context = useContext(MatchesContext);
  if (!context) {
    throw new Error('useMatches must be used within a MatchesProvider');
  }
  return context;
};
