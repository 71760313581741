import { Unsubscribe } from "firebase/firestore";

const subscriptions: Unsubscribe[] = [];

export const addSubscription = (unsubscribe: Unsubscribe) => {
  subscriptions.push(unsubscribe);
};

export const clearSubscriptions = () => {
  subscriptions.forEach((unsubscribe) => unsubscribe());
  subscriptions.length = 0;
};