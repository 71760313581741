import * as React from 'react';

export const useScreenSize = () => {
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [smallScreen, setSmallScreen] = React.useState(window.innerWidth < 930);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
      setSmallScreen(window.innerWidth < 930)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return {screenHeight, screenWidth, smallScreen}
}