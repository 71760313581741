import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Colors } from "@monorepo/shared";
import { ColorButton } from "./ColorButton";
import { useScreenSize } from "../hooks/screenSize";
import { ContactDialog } from "./Contact";

const questionsAndAnswers = [
  {
    question: "Vad kostar Reffi?",
    answer:
      "Domar- och tränarkonton är gratis, medan föreningskontot har en månadsavgift baserat på antalet matcher per månad – testa gratis första månaden!",
  },
  {
    question: "Hur skapar jag ett konto för min förening?",
    answer:
      "Du skapar enkelt ett konto för din förening genom att registrera dig på vår hemsida – det tar bara några minuter!",
  },
  {
    question: "Kan jag använda Reffi för alla typer av sporter?",
    answer:
      "Vi jobbar för att fungera för alla typer av sporter – både lagidrott och individuella grenar – och lägger ständigt till nya!",
  },
  {
    question: "Hur fungerar bokning och schemaläggning av domare?",
    answer:
      "Alla matcher är direkt kopplade till systemet, vilket gör bokning och schemaläggning av domare enkelt och automatiskt med full överblick.",
  },
  {
    question: "Hur kan jag få hjälp om jag stöter på problem?",
    answer:
      "Om du stöter på problem kan du enkelt kontakta vår support via kontakt@reffi.se – vi hjälper dig snabbt och effektivt!",
  },
  {
    question: "Finns Reffi som en App?",
    answer:
      "Reffi är en webbtjänst för föreningar och tränare, medan domare kan använda vår app för enkel åtkomst till sina matcher och schema.",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90vw", md: "70vw" },
  maxHeight: "80vh", // Constrain height to prevent overflow
  overflowY: "auto", // Allow scrolling for long content
  borderRadius: 1,
  padding: { xs: 2, md: 5 }, // Add padding around the content
  backgroundColor: Colors.primary,
};

export const QuestionDialog = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <Button
        variant="contained"
        size="large"
        onClick={() => setOpen(true)}
        sx={{ padding: 1.5, paddingX: 4, marginBottom: { xs: 3 } }}
      >
        Vill du veta mer?
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} sx={{ padding: 0 }}>
        <Paper sx={style}>
          <Questions />
        </Paper>
      </Modal>
    </Box>
  );
};

export const Questions = () => {
  const { smallScreen } = useScreenSize();

  return (
    <Stack
      width="100%"
      spacing={5}
      alignItems="center"
      sx={{ paddingBottom: 5, backgroundColor: Colors.primary }} // Add space at the bottom
      
    >
      <Typography
        variant={smallScreen ? "h4" : "h3"}
        color="secondary"
        textAlign="center"
        sx={{ marginBottom: 2 }} // Add space below the title
      >
        HAR DU FRÅGOR?
      </Typography>
      <Stack width="100%" spacing={1}>
        {questionsAndAnswers.map(({ question, answer }, index) => (
          <Accordion
            key={index}
            disableGutters // Removes internal padding
            sx={{
              backgroundColor: Colors.primary,
              color: Colors.white,
              boxShadow: "none",
              "&:before": { display: "none" }, // Remove default MUI divider line
              borderBottom: `1px solid ${Colors.white}`,
              borderRadius: 0, // Ensure no rounding by default
              "&:last-of-type": {
                borderRadius: 0, // Explicitly reset last item radius
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: Colors.white }} />}
              sx={{
                padding: { xs: 1, md: 2 },
                "& .MuiAccordionSummary-content": { margin: 0 },
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                {question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
      <Box marginTop={5}>
        <ContactDialog />
      </Box>
    </Stack>
  );
};