import { Colors } from "@monorepo/shared";
import { Box,  ListItem, ListItemButton, ListItemIcon, ListItemText,  } from "@mui/material";
import  { Dayjs,  } from 'dayjs';
import { DatePopUp } from "./DatePopUp";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { GridPrintExportOptions } from "@mui/x-data-grid";



interface DateTabProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setStartDate: (date: Dayjs | null) => void;
  setEndDate: (date: Dayjs | null) => void;
  apiRef: React.MutableRefObject<GridApiCommunity>;
}

export const DateTab = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  apiRef,
}: DateTabProps ) => {

  const print = async () => {    
    const printOptions: GridPrintExportOptions = {
      hideFooter: true,
      hideToolbar: true,
    };
    apiRef.current.resetRowHeights();
    apiRef.current.setDensity("compact");
    apiRef.current.resize();
    await new Promise(resolve => setTimeout(resolve, 100)); 

    apiRef.current.exportDataAsPrint(printOptions)
  }


  return (
    <Box>
      <ListItem>
        <DatePopUp setDate={setStartDate} date={startDate} title={["Från:", "Startdatum"]}/>
      </ListItem>
      <ListItem>
        <DatePopUp setDate={setEndDate} date={endDate} title={["Till:", "Slutdatum"]}/>
      </ListItem>
      <ListItem>
        <ListItemButton  component="button" onClick={print}>
          <ListItemIcon><ImportExportIcon color="secondary" /></ListItemIcon>
          <ListItemText primary="Exportera" sx={{ textAlign: "center", color: Colors.white }} />
        </ListItemButton>
      </ListItem>
    </Box>
  );
};