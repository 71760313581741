import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid2,
  List,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase/firebase";
import { Invitation, paths, Referee } from "@monorepo/shared";
import { useAppContext } from "contexts/AppContext";
import { useClub } from "contexts/ClubContext"; 

interface EditRefereeProps {
  selectedUser?: Invitation;
  referee?: Referee
  removeSelected: () => void;
  clubId: string;
}

export const EditReferee = ({
  selectedUser,
  referee,
  clubId,
  removeSelected,
}: EditRefereeProps) => {
  const [user, setUser] = useState<Invitation | undefined>(selectedUser);

  const {setStatusMessage, setOpenStatusMessage, setStatusMessageType} = useAppContext();
  const {club} = useClub();

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  const updateInvitationStatus = async (
    id: string,
    status: "accepted" | "denied",
    role?: Invitation["role"],
  ) => {
    if (!clubId || !id) return;
    const invitationRef = doc(db, paths.invitations, `${clubId}_${id}`);
    const clubRef = doc(db, paths.clubs, clubId);
    try {
      await updateDoc(invitationRef, { status });
      if (status === "accepted" && role) {
        await updateDoc(clubRef, {
          [`users.${id}`]: role,
        });
      }
      if (role === "referee" && user) {
        updatePermissions(user);
      }
      setStatusMessage(status === "accepted" ? "Domare tillagd" : "Domare borttagen");
    } catch (error) {
      setStatusMessage("Kunde inte" + status === "accepted" ? "lägga till domare" : "ta bort domare");
      setStatusMessageType("error");
      console.error("Error updating invitation status:", error);
    } finally {
      setOpenStatusMessage(true);
    }
  };

  const updatePermissions = async (updatedUser: Invitation) => {
    if (!clubId || !updatedUser?.id) return;
    const invitationRef = doc(
      db,
      paths.invitations,
      `${updatedUser.clubId}_${updatedUser.id}`
    );
    try {
      await updateDoc(invitationRef, {permissions : updatedUser.permissions,});
      setStatusMessage("Updaterade behörigheter");
    } catch (error) {
      setStatusMessage("Misslyckades med att uppdatera");
      setStatusMessageType("error");
      console.error("Failed to update permissions:", error);
    } finally {
      setOpenStatusMessage(true);
    }
  };
  
  const handlePermissionChange = (permission: number, checked: boolean) => {
    const permissions = [...(user?.permissions || [])];
    if (checked && !permissions?.includes(permission)) {
      permissions?.push(permission)
    } 
    if (!checked && permissions?.includes(permission)) {
      const index = permissions.indexOf(permission);
      permissions.splice(index, 1);
    }
    setUser((prev) => (prev ? { ...prev, permissions: permissions } : prev));
  }

  const handleSave = () => {
    if (user?.status !== "accepted") {
      updateInvitationStatus(user?.userId!, "accepted", user?.role);
    } else {
      if (selectedUser !== user && user) {
        updatePermissions(user);
      }
    }
    handleModalClose();
  }

  const handleModalClose = () => {
    removeSelected();
  };

  return (
    <Dialog disableAutoFocus fullWidth open={!!selectedUser} onClose={handleModalClose} sx={{alignContent: "center", top: -300}}>
      <DialogContent>
        <Box margin={1}>
          <Typography variant="h5">
            {user?.name}
          </Typography>
          <Typography color="textSecondary">
            {user?.email}
          </Typography> 
        </Box>
        <Divider />
        {user && user.role === "referee" && (
          <Box margin={1}>
            <Typography>
              Dömer: 
            </Typography>
            <FormGroup>
              {(Object.entries(club?.settings.matchTypes || {})).map(([matchType, {displayValue}]) => {
                const permission = parseInt(matchType);
                return <FormControlLabel control={
                <Checkbox key={displayValue} checked={(user.permissions || []).includes(permission)} onChange={(e) => handlePermissionChange(permission, e.target.checked)} />
                } label={displayValue} />
              })}
            </FormGroup>
        </Box>
        )}
        <Divider/>
        <Grid2 container spacing={2} margin={1}>

          {/* Telefon */}
          <Grid2 size={{xs: 12, md: 6}} >
            <Typography variant="subtitle1" fontWeight="bold">
              Telefon:
            </Typography>
            <Typography>{user?.phoneNumber || "N/A"}</Typography>
          </Grid2>

          {/* Personnummer */}
          {referee && (
            <Grid2  size={{xs: 12, md: 6}} >
              <Typography variant="subtitle1" fontWeight="bold">
                Personnummer:
              </Typography>
              <Typography>{referee.socialSecurityNumber}</Typography>
            </Grid2>
          )}

          {/* Bank */}
          {user?.status === "accepted" && referee && (
            <Grid2  size={{xs: 12, md: 6}} >
              <Typography variant="subtitle1" fontWeight="bold">
                Bank:
              </Typography>
              <Typography>{referee.bank}</Typography>
            </Grid2>
          )}

          {/* Bankuppgifter */}
          {user?.status === "accepted" && referee && (
            <Grid2  size={{xs: 12, md: 6}} >
              <Typography variant="subtitle1" fontWeight="bold">
                Bankuppgifter:
              </Typography>
              <Typography>
                {referee.clearingNumber}-{referee.accountNumber}
              </Typography>
            </Grid2>
          )}
        </Grid2>
      </DialogContent>
      <DialogActions sx={{borderTop: "1px solid lightGrey"}}>
        <Stack direction="row" width={"100%"} padding={1} justifyContent={"space-between"}>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              updateInvitationStatus(user?.userId!, "denied");
              removeSelected();
            }}
          >
            {user?.status === "accepted" ? "Ta bort" : "Neka"}
          </Button>
          <Stack direction="row" spacing={3} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleModalClose}>
              Avbryt
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSave(); 
                removeSelected();
              }}
            >
              {user?.status === "accepted" ? "Spara" : "Acceptera"}
            </Button>
          </Stack>

        </Stack>
      </DialogActions>
    </Dialog>
  );
};
