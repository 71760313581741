import { doc, setDoc, Timestamp } from "firebase/firestore";
import { auth, db } from "config/firebase/firebase";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { Club, Invitation, paths, User as ClubUser, UserRole } from "@monorepo/shared";
import { FirebaseError } from "firebase/app";

export async function createClubInFirestore(club: Club, size: string) {
  const paymentDoc = doc(db, "stripePayments", club.id);

  const nextPayment = new Date();
  nextPayment.setMonth(nextPayment.getMonth() + 1)

  await setDoc(paymentDoc, {
    dueDate: Timestamp.fromDate(nextPayment),
    previousPayment: Timestamp.fromDate(new Date()),
    clubName: club.clubName, 
    clubSize: size,
  });

  await setDoc(doc(db, paths.clubs, club.id), club);
}

export async function createInvitationInFirestore(user: ClubUser, club: Club, isNewClub: boolean, role: UserRole) {
  
  const invitation: Invitation = {
    userId: user.id,
    clubId: club.id,
    clubName: club.clubName,
    name: user.name,
    email: user.email,
    phoneNumber: user.phoneNumber,
    role: isNewClub ? "responsible" : role,
    status: isNewClub ? "accepted" : "invited",
  };
  const invitationsCollectionRef = doc(db, paths.invitations, `${invitation.clubId}_${invitation.userId}`);
  await setDoc(invitationsCollectionRef, invitation);
}

export const defaultClubSettings = {
  referees: 1,
  matchTypes: {
    '900': { compensation: 150, displayValue: "5-5" },
    '1200': { compensation: 345, displayValue: "7-7" },
    '1500': { compensation: 430, displayValue: "9-9" },
    '2400': { compensation: 520, displayValue: "11-11" },
  },
  questions: [
    "När och hur kommer jag att få min lön för att döma matchen?",
    "Vilken tid bör jag vara på plats före matchstart?",
    "Vad behöver jag ta med mig till matchen? (t.ex. visselpipa, matchkort, gula/röda kort, tidtagarur, domartröja)",
    "Kommer det att finnas tillgång till omklädningsrum eller annan plats där jag kan förbereda mig?",
  ].map(question => ({ question, awnser: "" })),
};