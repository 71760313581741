import * as React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Colors } from "@monorepo/shared";
import { useScreenSize } from "../hooks/screenSize";

export const Socials = () => {
  const { smallScreen } = useScreenSize();

  return (
    <Stack
      width="100%"
      marginTop={15}
      padding={smallScreen ? 2 : 5}
      spacing={5}
      alignItems="center"
    >
      <Typography
        gutterBottom
        variant={smallScreen ? "h3" : "h2"}
        component="div"
        textAlign="center"
      >
        <span style={{ color: Colors.primary }}>
          Följ utvecklingen på våra sociala medier
        </span>
      </Typography>
      <Stack
        alignItems="center"
        direction={smallScreen ? "column" : "row"}
        justifyContent="center"
        spacing={smallScreen ? 3 : 1}
        width="100%"
      >
        {[
          {
            src: require("../assets/images/facebook_reffi.jpg"),
            alt: "Facebook",
            link: "https://www.facebook.com/profile.php?id=61560848564067",
          },
          {
            src: require("../assets/images/instagram_reffi.jpg"),
            alt: "Instagram",
            link: "https://www.instagram.com/reffi.se/",
          },
          {
            src: require("../assets/images/linkedin_reffi.jpg"),
            alt: "LinkedIn",
            link: "https://www.linkedin.com/company/reffi",
          },
        ].map(({ src, alt, link }, index) => (
          <Box width={smallScreen ? "60%" : "30%"} key={index} textAlign={"center"}>
            <img
              src={src}
              alt={alt}
              onClick={() => window.open(link, "_blank")}
              style={{
                width: "100%",
                maxWidth: smallScreen ? 300 : "none",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
            {smallScreen && <Button
              size={"medium"}
              color="primary"
              variant="outlined"
              onClick={() => window.open(link, "_blank")}
              sx={{
                borderRadius: 30,
                width: "60%" ,
                textAlign: "center",
              }}
            >
              {alt}
            </Button>}
          </Box>
        ))}
      </Stack>
      {!smallScreen && <Stack
        alignItems="center"
        direction={"row"}
        justifyContent="center"
        padding={5}
        spacing={"10%"}
      >
        {[
          {
            label: "Facebook",
            link: "https://www.facebook.com/profile.php?id=61560848564067",
          },
          {
            label: "Instagram",
            link: "https://www.instagram.com/reffi.se/",
          },
          {
            label: "LinkedIn",
            link: "https://www.linkedin.com/company/reffi",
          },
        ].map(({ label, link }, index) => (
          <Button
            key={"web_"+index}
            size={smallScreen ? "medium" : "large"}
            color="primary"
            variant="outlined"
            onClick={() => window.open(link, "_blank")}
            sx={{
              borderRadius: 30,
              width: "auto",
              textAlign: "center",
            }}
          >
            {label}
          </Button>
        ))}
      </Stack>}
    </Stack>
  );
};
